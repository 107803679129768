<template>
  <div class="">
    <div
      class="d-flex justify-content-center align-items-center p-4"
      v-if="!dataLoaded"
    >
      <div
        class="spinner-border text-primary"
        style="height: 4rem; width: 4rem"
      ></div>
    </div>
    <div v-else>
      <form @submit.prevent="onSubmitGoal()">
        <div>
          <div class="mb-2">
            <label for="project_manager_id" class="form-label mandatory"
              >Assign To</label
            >
            <select
              v-model="reqObj.assigned_to_user"
              id="project_manager_id"
              class="form-control"
              :state="null"
              required
              oninvalid="this.setCustomValidity('Please select project manager name')"
              oninput="this.setCustomValidity('')"
            >
              <option value="">Select User</option>
              <option
                :key="index"
                v-for="(item, index) in users"
                :value="item.id"
              >
                {{ item.first_name }} {{ item.last_name }}
              </option>
            </select>
          </div>
          <div class="mb-2">
            <label for="addgoal_title" class="form-label mandatory">Title</label>
            <input
              type="text"
              rows="2"
              wrap="soft"
              id="addgoal_title"
              class="form-control"
              placeholder="Enter the goal title"
              v-model="reqObj.title"
              required
            />
          </div>
          <div class="mb-2">
            <label for="project" class="form-label mandatory">Start Date</label>
            <datepicker
              placeholder="yyyy-mm-dd"
              type="date"
              :format="formateDate"
              v-model="reqObj.start_date"
              :clear-button="true"
              :calendar-button="true"
              :clear-button-icon="'fa fa-times'"
              :input-class="'border-start-0'"
              :calendar-button-icon="'fa fa-calendar'"
              :bootstrap-styling="true"
              :typeable="true"
              required
            ></datepicker>
            <small class="text-danger" v-if="errorObj.sdterr" v-text="errorObj.sdterr"></small>
          </div>
          <div class="mb-2">
            <label for="project" class="form-label mandatory">End Date</label>
            <datepicker
              placeholder="yyyy-mm-dd"
              type="date"
              :disabled-dates="{ to: new Date(reqObj.start_date) }"
              :format="formateDate"
              v-model="reqObj.end_date"
              :clear-button="true"
              :calendar-button="true"
              :clear-button-icon="'fa fa-times'"
              :input-class="'border-start-0'"
              :calendar-button-icon="'fa fa-calendar'"
              :bootstrap-styling="true"
              :typeable="true"
              required
              ></datepicker>
              <small class="text-danger" v-if="errorObj.edterr" v-text="errorObj.edterr"></small>
          </div>
          <div class="mb-2">
            <label for="addgoal_description" class="form-label mandatory"
              >Description</label
            >
            <textarea
              id="addgoal_description"
              rows="2"
              wrap="soft"
              class="form-control"
              v-model="reqObj.description"
              :state="null"
              placeholder="Enter the description"
              oninvalid="this.setCustomValidity('Please enter goal')"
              oninput="this.setCustomValidity('')"
              required
            ></textarea>
          </div>
          <div class="mb-3">
            <label for="goal" class="mandatory form-label">Progress</label>
              <input
                class="p-0 col-md-5 col-10"
                type="range"
                min="0"
                max="100"
                step="1"
                v-model="reqObj.progress"
              />
              <b class="p-1 ms-2 bg-primary text-light rounded"
                ><span v-text="reqObj.progress"></span>%</b
              >
          </div>
        </div>
        <div class="text-end">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import myMixin from "@/mixins/Permission";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";

export default {
  name: "AddGoals",
  mixins: [myMixin],
  props:['goal_id'],
  components: {
    Datepicker,
    UnauthorizedPage,
  },
  data: function () {
    return {
      reqObj: {
        start_date: new Date().toISOString().slice(0, 10),
        end_date: "",
        title: "",
        description: "",
        assigned_to_user: "",
        progress: 0,
        goal_id: 0,
      },
      errorObj:{
        sdterr:'',
        edterr:'',
      },
      users: [],
      percentage: ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
      permissions: window.localStorage.getItem("permissions"),
      editid: 0,
      dataLoaded:false
    };
  },

  mounted() {
    this.getUserPermissions();
    $("#goalModal").on("hidden.bs.modal",this.hideModalEventHandler)
    if (this.permissions.includes("goals.add")) {
      this.getUsers();
      if (
        typeof this.$route.params.id != "undefined" &&
        this.$route.params.id != 0
      ) {
        this.editid = this.$route.params.id;
        this.getGoalById(this.editid);
      }
    }
  },
  methods: {
    getUsers() {
      user.getmanageridDropdown().then((result) => {
        this.users = result.data.data;
      });
    },
    format(date){
      return moment(date).format("YYYY-MM-DD")
    },
    getGoalById(id) {
      user.getGoalById(id).then((result) => {
        this.reqObj.assigned_to_user = result.data.data["user_id"];
        this.reqObj.start_date = result.data.data["start_date"];
        this.reqObj.end_date = result.data.data["end_date"];
        this.reqObj.title = result.data.data["title"];
        this.reqObj.description = result.data.data["description"];
        this.reqObj.progress = result.data.data["progress"];
        this.dataLoaded=true;
      });
    },
    onSubmitGoal() {

      if(!this.reqObj.start_date){
        this.errorObj.sdterr="** Please Select Date **";
        return;
      }
      else{
        this.errorObj.sdterr="";
      }

      if(!this.reqObj.end_date){
        this.errorObj.edterr="** Please Select Date **";
        return;
      }
      else{
        this.errorObj.edterr="";
      }

      if (!this.goal_id) {
        user.CreateUserGoal(this.reqObj).then((result) => {
          if(result.data.code==200){
            Swal.fire({
              title:"Success",
              text:"Goal Added Successfully !!!",
              icon:"success",
              timer:2500
            })
            this.$emit('close-modal');
          }
          else{
            Swal.fire({
              title:"Error",
              text:"Failed to Add Goal !!!",
              icon:"error",
              timer:2500
            })
            this.$emit('close-modal');
          }
        })
        .catch(err=>{
          Swal.fire({
            title:"Error",
            text:"Something Went Wrong !!!",
            icon:"error",
            timer:2500
          })
          this.$emit('close-modal');
        });
      } else {
        this.reqObj.goal_id = this.goal_id;
        const dataObj = {
            action:'update',
            start_date: this.reqObj.start_date,
            end_date: this.reqObj.end_date,
            title: this.reqObj.title,
            description: this.reqObj.title,
            assigned_to_user: this.reqObj.assigned_to_user,
            progress: this.reqObj.progress,
            goal_id: this.goal_id,
          };
        user.UpdateGoalDetails(dataObj).then((result) => {
          if(result.data.code==200){
            Swal.fire({
              title:"Success",
              text:"Goal Updated Successfully !!!",
              icon:"success",
              timer:2500
            })
            this.$emit('close-modal');
          }
          else{
            Swal.fire({
              title:"Error",
              text:"Failed To Update Goal !!!",
              icon:"error",
              timer:2500
            })
            this.$emit('close-modal');
          }
        }).catch(err=>{
          Swal.fire({
            title:"Error",
            text:"Something Went Wrong !!!",
            icon:"error",
            timer:2500
          })
          this.$emit('close-modal');
        });
      }
    },
    formateDate(date) {
      let fdate=new Date(date).toISOString().substring(0,10);
      return fdate;
    },
  },
  watch:{
    goal_id:{
      handler(nv,ov){
        if(nv){
          this.getGoalById(nv);
        }
        else{
          this.dataLoaded=true;
        }
      },
      immediate:true
    }
  }
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.v-text-field {
  width: 400px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}
::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
