<template>
  <div>
    <!-- Page Heading -->
    <!-- <div class=""> -->
    <!-- <h1 class="h3 mb-0 text-gray-800">Dashboard</h1> -->
    <!-- <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                class="fas fa-download fa-sm text-white-50"></i> Generate Report</a> -->
    <!-- </div> -->
    <!-- <div class="row heading-text-14 ml-0 mt-2">
        Time spent today :<b class="ml-2 bg-primary text-light pl-2 pr-2">{{live_time.hour.toLocaleString(undefined,{minimumIntegerDigits: 2})+" : "+live_time.minute.toLocaleString(undefined,{minimumIntegerDigits: 2})+" : "+live_time.second.toLocaleString(undefined,{minimumIntegerDigits: 2})}}</b>
        </div> -->
    <!-- <div class="d-inline-block">
            <a v-if="!currentUser.state || currentUser.state == null" @click="updateUser('start')" class="btn btn-primary mt-2"><i class="fas fa-sm text-white-50"></i> Start</a>
            <a v-if="currentUser.state =='work'" @click="updateUser('stop')" href="#" class="btn btn-primary mt-2"><i class="fas fa-sm text-white-50"></i> Stop</a>
            <a v-if="currentUser.state =='break' || currentUser.state =='lunch'" @click="updateUser('resume')" href="#" class="btn btn-primary mt-2"><i class="fas fa-sm text-white-50"></i> Resume</a>
        </div> -->
    <Dialog
      :show="showDialog"
      :cancel="cancel"
      :confirm="confirm"
      title="Select stop time"
    />
    <!-- <div class="container-fluid mx-0 mt-5 p-0">
          <ReportTaskDashboard></ReportTaskDashboard>
        </div> -->
    <!-- <img src="../img/clapping.gif" style="width:10rem;height:auto" /> -->
    <div>
      <DashboardOverview />
    </div>
    <!-- <div>
      <DashboardSummary />
    </div> -->
    <ReportTaskDashboard v-if="permissions"></ReportTaskDashboard>
  </div>
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import myMixin from "@/mixins/Permission";
import RewardChart from "./RewardChart.vue";
import ReportTaskDashboard from "./ReportTaskDasboard.vue";
import Datepicker from "vuejs-datepicker";
import swal from "sweetalert2";
import Dialog from "@/components/Dialog.vue";
import "sweetalert2/dist/sweetalert2.min.css";
import DashboardOverview from "./DashboardOverview.vue";
// import DashboardSummary from "./DashboardSummary.vue";
window.Swal = swal;
export default {
  name: "Dashboard",
  mixins: [myMixin],
  components: {
    Datepicker,
    ReportTaskDashboard,
    Dialog,
    DashboardOverview,
    // DashboardSummary,
  },
  data: function () {
    return {
      reqObj: {
        flag: "",
        user_id: "",
        breakType: "",
        breakTime: null,
      },

      showDialog: false,

      breakArr: [
        { value: "lunch", text: "Lunch" },
        { value: "other", text: "Other" },
      ],
      currentPage: "",
      isLoading: false,
      columns: [
        {
          label: " Project",
          field: "project_name",
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Project name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: " Mile Stone",
          field: "name",
          type: "value",
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Milestone name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: " Summary",
          field: "short_desc",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Summary", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
          },
        },
        {
          label: "Description",
          field: "description",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Description", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Start Date",
          field: "start_date",
          sortable: true,
          filterable: true,
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Due Date",
          field: "due_date",
          sortable: true,
          filterable: true,
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "MM/DD/YYYY", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Estimated hours",
          field: "estimated_hours",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Estimated hours", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "disable-sorting",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 10,
      },
      permissions: localStorage.getItem("permissions"),
    };
  },

  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),

    cancel() {
      this.showDialog = false;
      this.showDialogUser = false;
    },
    confirm(datetime) {
      var date = new Date(datetime);
      datetime = date.toISOString().substring(0, 19).replace("T", " ");

      this.showDialog = false;

      if (datetime != "") {
        var flag = "stop";
        this.reqObj.flag = flag;
        this.reqObj.end_time = datetime;
        user.updateUser(this.reqObj).then((result) => {
          this.reqObj.breakType = "";
          this.reqObj.breakTime = null;
          this.getUserPermissions();
        });
      }
    },

    search_task() {
      var fields = {
        filters: this.serverParams.columnFilters,
        messageData: this.message,
        sort: this.serverParams.sort,
      };
      //console.log("searchtask",fields);
      user.search_task(fields).then((result) => {
        this.rows = [];
        this.reqObj = result.data;
        this.rows = result.data;
      });
    },
    delete_task(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "",
        confirmButtonText: "Yes, Delete it!",
        closeOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal("Deleted", "You successfully delete this task", "success");
          user.delete_task(id).then((result) => {
            this.getAllTasks();
          });
        } else {
          user.delete_task(id, (error) => {});
        }
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.search_task();
    },
    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: Object.assign({}) };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      //console.log("params",params);
      {
        field: "project_name";
        field: "name";
        field: "short_desc";
        field: "description";
        field: "start_date";
        field: "due_date";
        field: "estimated_hours";
      }
      this.serverParams.page = 1;
      var filtersPr = JSON.parse(JSON.stringify(this.serverParams));
      var filters = filtersPr.columnFilters;
      //console.log("task filters",filters);
      this.search_task();
    },

    updateUser(flag, isValid) {
      if (isValid) {
        if (!this.reqObj.breakType) {
          alert("Please select any one options for break");
          return false;
        }
        if (!this.reqObj.breakTime) {
          alert("Please add valid time in minutes");
          return false;
        }
        if (this.reqObj.breakTime < 15) {
          alert("Break time can not be less than of 15 minute");
          return false;
        }
        if (this.reqObj.breakTime > 120) {
          alert("Break time can not be more than of 120 minute");
          return false;
        }
      }
      this.reqObj.flag = flag;
      user.updateUser(this.reqObj).then((result) => {
        this.reqObj.breakType = "";
        this.reqObj.breakTime = null;
        let modalContent = result.data;
        if (result.data.code == "422") {
          alert(result.data.message);
          window.location.reload();
        }
        if (modalContent.data.show_modal) {
          swal.fire({
            imageUrl: modalContent.data.applause
              ? "img/clapping.gif"
              : "img/sad2.gif",
            imageHeight: 180,
            imageWidth: 180,
            title: modalContent.message,
            html: modalContent.data.applause
              ? ""
              : '<b>Your are <span style="color:red">' +
                modalContent.data["late count"] +
                "</span> time late in this month</b>",
            background: modalContent.data.applause
              ? 'url("../img/pp.jpg") no-repeat center center/cover'
              : "",
            timer: 2500,
            padding: "2rem",
            showConfirmButton: false,
          });
        }
        if (Object.hasOwn(modalContent.data, "reward")) {
          setTimeout(() => {
            swal.fire({
              toast: true,
              html: `&nbsp;${modalContent.data.reward}`,
              iconHtml:
                "<img src='img/icons8-confetti.gif' style='width:1.8em;'></img>",
              position: "top-right",
              iconColor: "white",
              timer: 3000,
              showConfirmButton: false,
            });
          }, 2500);
        }
        // if(!modalContent.data.applause){
        //   swal.fire({
        //     imageUrl:'img/sad2.gif',
        //     imageHeight:180,
        //     imageWidth:180,
        //     title:modalContent.message,
        //     padding:'2rem',
        //     timer:3500,
        //     showConfirmButton:false
        //   })
        // }
        // this.TodayTimeSpent()
        this.getUserPermissions();
      });
    },
    logout() {
      this.logoutUser();
      this.$nextTick(() => {
        this.getUserPermissions();
      });
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    getTodaysQuotes() {
      // console.log("hii");
      user.getTodaysQuotes().then((result) => {
        if (result.data.data.id != null) {
          if (result.data.data.image != " ") {
            Swal.fire({
              title: result.data.data.text,
              imageUrl: result.data.data.image_url,
              imageWidth: 450,
              imageHeight: 250,
              imageAlt: "Custom image",
            }).then((result1) => {
              if (result1.isConfirmed) {
                this.AttendanceAlert();
              } else {
                this.AttendanceAlert();
              }
            });
          } else {
            Swal.fire({
              title: result.data.data.text,
            }).then((result1) => {
              if (result1.isConfirmed) {
                this.AttendanceAlert();
              } else {
                this.AttendanceAlert();
              }
            });
          }
        } else {
          this.AttendanceAlert();
        }
      });
    },
    AttendanceAlert() {
      user.getAttendanceByDate().then((result) => {
        if (
          (result.data.data.login == false) &
          (result.data.data.end_time.last_log == false)
        ) {
          swal
            .fire({
              title: "Would you like to start your day",
              showDenyButton: true,
              denyButtonText: `No`,
              confirmButtonText: "Yes",
              confirmButtonColor: "#32a852",
              denyButtonColor: "#f00",
              allowOutsideClick: false,
              icon: "question",
            })
            .then((result1) => {
              /* Read more about isConfirmed below */
              if (result1.isConfirmed) {
                var flag = "start";
                this.reqObj.flag = flag;
                user
                  .updateUser(this.reqObj)
                  .then((result) => {
                    this.reqObj.breakType = "";
                    this.reqObj.breakTime = null;
                    // this.TodayTimeSpent();
                    // this.getUserPermissions();
                    let modalContent = result.data;
                    // console.log(modalContent);
                    if (modalContent.data.show_modal) {
                      swal.fire({
                        imageUrl: modalContent.data.applause
                          ? "img/clapping.gif"
                          : "img/sad2.gif",
                        imageHeight: 180,
                        imageWidth: 180,
                        title: modalContent.message,
                        html: modalContent.data.applause
                          ? ""
                          : '<b>Your are <span style="color:red">' +
                            modalContent.data["late count"] +
                            "</span> time late in this month</b>",
                        background: modalContent.data.applause
                          ? 'url("../img/pp.jpg") no-repeat center center/cover'
                          : "",
                        timer: 2500,
                        padding: "2rem",
                        showConfirmButton: false,
                      });
                    }
                    if (Object.hasOwn(modalContent.data, "reward")) {
                      setTimeout(() => {
                        swal.fire({
                          toast: true,
                          html: `&nbsp;${modalContent.data.reward}`,
                          // background:"#318CE7",
                          iconHtml:
                            "<img src='img/icons8-confetti.gif' style='width:1.8em;'></img>",
                          position: "top-right",
                          iconColor: "white",
                          timer: 3000,
                          showConfirmButton: false,
                        });
                      }, 2500);
                    }
                  })
                  .then(() => {
                    this.getUserPermissions();
                  });
                // this.$forceUpdate();
              }
            });
        }
        // else if (
        //   (result.data.data.login == false) &
        //   (result.data.data.end_time.last_log == true)
        // )
        // {
        //   swal
        //     .fire({
        //       title:
        //         "You forgot to stop your day. would you like to stop it ? ",
        //       showDenyButton: true,
        //       denyButtonText: `No`,
        //       confirmButtonText: "Yes",
        //       confirmButtonColor: "#32a852",
        //       denyButtonColor: "#f00",
        //     })
        //     .then((result2) => {
        //       if (result2.isConfirmed) {
        //         this.start_time = result.data.data.end_time.last_log_start;
        //         this.showDialog = true;
        //         // swal.fire({
        //         // title: "Confirm details?",
        //         // html:'<datepicker></datepicker>',
        //         // type: "warning",
        //         //     })
        //       }
        //     });
        // }
        else {
        }
      });
    },
  },

  mounted() {
    this.setCurrentPage("Dashboard");
    // this.getTodaysQuotes()
    this.getUserPermissions();
    // swal.fire({
    //   toast:true,
    //   html:`<b>dsadsa<br>dsadsa</b>`,
    //   iconHtml:"<img src='img/icons8-confetti.gif' style='width:1.8em;'></img>",
    //   customClass:{
    //     icon:"no-border"
    //   },
    //   position:"top-right",
    //   iconColor:"white",
    //   padding:'0.5em 1em 0.5em',
    //   // timer:2500,
    //   showConfirmButton:false
    // })
    // $('.fl-flex-label').flexLabel();
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}

.disableButton {
  opacity: 0.5;
  pointer-events: none;
}

/* .no-border{
  border: 0 !important;
} */
</style>
