<template>
  <!-- Begin Page Content -->
  <div>
    <div
      class="card shadow-sm border-0 my-4"
      v-if="permissions.includes('snapshot.report')"
    >
      <div class="card-body">
        <div class="pb-2 px-1 row">
          <h4 class="m-0 font-weight-bold text-primary">Snapshot</h4>
        </div>
        <div class="my-2">
          <redmine-filter
            page="snapshot_report"
            ref="redmineFilter"
            :serverParams="serverParams"
            @redmine-filter-data="redmineFilterHandler"
            @reset-server-params="onResetServerParams"
          ></redmine-filter>
        </div>
        <div class="p-2">
          <div
            class="spinner-border text-primary my-5 mx-auto d-block"
            style="width: 4.5rem; height: 4.5rem"
            v-if="Array.isArray(rows) && rows.length == 0"
          ></div>
          <div class="text-center p-5" v-else-if="rows == null">
            <h3>No data available</h3>
          </div>
          <div v-else>
            <h5
              class="text-primary text-md-start text-center mb-2 m-0"
              v-text="`Total: ${rows.length}`"
            ></h5>
            <div class="overflow-auto">
              <vue-good-table
                :rows="rows"
                :columns="column"
                max-height="800px"
                styleClass="vgt-table striped no-border"
                :sort-options="{
                  enabled: true,
                  initialSortBy: { field: 'username', type: 'asc' },
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <div
                    v-if="
                      props.column.label == 'Leaves Applied' && rows.length > 0
                    "
                  >
                    <popper
                      :disabled="
                        rows[props.row.originalIndex].applied_leaves == null
                      "
                      :append-to-body="true"
                      :trigger="popperOptions.trigger"
                      :options="popperOptions.options"
                    >
                      <div class="popper p-2 text-start">
                        <small
                          class="d-block"
                          v-for="item in rows[props.row.originalIndex]
                            .applied_leaves"
                          v-text="item"
                        ></small>
                      </div>
                      <div class="d-inline-block" slot="reference">
                        Total : {{ props.row.total_leaves }}
                      </div>
                    </popper>
                  </div>
                  <div
                    v-else-if="
                      props.column.label == 'Leaves Approved' && rows.length > 0
                    "
                  >
                    <popper
                      :disabled="
                        rows[props.row.originalIndex].approved_leaves == null
                      "
                      :trigger="popperOptions.trigger"
                      :options="popperOptions.options"
                    >
                      <div class="popper p-2 text-start">
                        <small
                          class="d-block"
                          v-for="item in rows[props.row.originalIndex]
                            .approved_leaves"
                          v-text="item"
                        ></small>
                      </div>
                      <div class="d-inline-block" slot="reference">
                        {{ props.row.total_approved_leaves }}
                      </div>
                    </popper>
                  </div>
                  <div
                    v-else-if="
                      props.column.label == 'Discrepancy' && rows.length > 0
                    "
                  >
                    <popper
                      :disabled="
                        rows[props.row.originalIndex].discrepancy_dates == null
                      "
                      clas="border"
                      :trigger="popperOptions.trigger"
                      :options="popperOptions.options"
                    >
                      <div class="popper p-2">
                        <div class="text-start" style="font-size: 12px">
                          <span
                            >Total :
                            {{
                              rows[props.row.originalIndex]
                                .total_discrepancy_hours
                            }}</span
                          >
                        </div>
                        <small
                          class="d-block cursor-pointer link link-primary link-underline link-underline-opactity-0 link-underline-opacity-100-hover text-start"
                          v-for="item in rows[props.row.originalIndex]
                            .discrepancy_dates"
                          @click="
                            redirectToReport(
                              rows[props.row.originalIndex].id,
                              item.date
                            )
                          "
                        >
                          {{ item.date }} ({{ item.hours }})
                        </small>
                      </div>
                      <div
                        class="d-inline-block cursor-pointer"
                        slot="reference"
                      >
                        {{ props.row.total_discrepancy_count }}
                      </div>
                    </popper>
                  </div>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <UnauthorizedPage></UnauthorizedPage>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import moment from "moment";
import EventBus from "../../helpers/event-bus";
moment.locale("en");
import { mapState, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import myMixin from "@/mixins/Permission";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
// import VueDatetimePickerJs from "vue-date-time-picker-js";
import Datepicker from "vuejs-datepicker";
import RedmineFilter from "../../components/redmine_filter.vue";
import "vue-good-table/dist/vue-good-table.css";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";

const date = new Date();

export default {
  name: "Daily_Discrepancy",
  mixins: [myMixin],
  components: {
    Datepicker,
    // datePicker: VueDatetimePickerJs,
    Multiselect,
    UnauthorizedPage,
    RedmineFilter,
    Popper,
  },
  data: function () {
    return {
      noData: false,
      disabledMonths: {
        from: new Date(),
      },
      popperOptions: {
        trigger: "clickToOpen",
        options: {
          placement: "left",
          modifiers: {
            offset: { offset: "0,5px" },
            preventOverflow: {
              enabled: true,
              tether: false,
              boundariesElement: "document",
            },
          },
        },
      },
      reqObj: {
        filtered_users: [],
        user_organization: [],
        projects: [],
        clients: [],
        subclients: [],
        report_to_users: [],
      },
      config: {
        date: null,
        mode: "range",
        dateFormat: "Y-m-d",
        defaultDate: [
          new Date().toISOString().substring(),
          new Date().toISOString().substring(),
        ],
        enable: [
          {
            from: "2000-01-01",
            to: new Date().toISOString().substring(0, 10),
          },
        ],
      },
      user_organizations: [],
      active_users_list: [],

      serverParams: {
        columnFilters: {
          month: null,
        },
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: -1,
      },

      selected_months: {
        month: new Date(),
      },
      rows: [],
      column: [
        {
          label: "Username",
          field: "username",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Working Days",
          field: ({ total_working_days, actual_working_days }) =>
            `${actual_working_days} | ${total_working_days}`,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Discrepancy",
          field: "total_discrepancy_count",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Hours",
          field: this.getElapsedHours,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Reported",
          field: "total_reported_hours",
          formatFn: this.ConvertMinutesToTime2,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Billable",
          field: "total_billable_hours",
          formatFn: this.ConvertMinutesToTime2,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Non billable",
          field: "total_non_billable_hours",
          formatFn: this.ConvertMinutesToTime2,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Not Reported",
          field: "total_not_reported_hours",
          formatFn: this.ConvertMinutesToTime2,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Approved",
          field: "total_approved_hours",
          formatFn: this.ConvertMinutesToTime2,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Not Approved",
          field: "total_not_approved_hours",
          formatFn: this.ConvertMinutesToTime2,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Leaves Applied",
          field: "total_leaves",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Leaves Approved",
          field: "total_approved_leaves",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
      ],

      permissions: window.localStorage.getItem("permissions"),
    };
  },
  mounted() {
    this.setCurrentPage("Reports");
    if (this.permissions.includes("reports.read")) {
      localStorage.setItem("snapshot-params", JSON.stringify([]));
      // this.FilterApply(); //month pass remaining
    }
  },
  destroyed() {
    localStorage.removeItem("snapshot-params");
  },

  watch: {
    "selected_months.month": function (nv, ov) {
      // console.log(nv);
      if (nv != ov) {
        this.FilterApply();
        // console.log("watcher")
        this.userListFilter();
      }
    },
  },

  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    onResetServerParams(severParams) {
      // this.serverParams.page = params.page;
      // this.serverParams.perpage = params.perpage;
      this.serverParams = Object.assign({}, severParams, { perpage: -1 });
      this.$nextTick(() => this.$refs.redmineFilter.applyFilter());
    },
    redirectToReport(user_id, date) {
      const redirect_link = this.$router.resolve({ name: "Reports" });
      window.open(redirect_link.href, "_blank");
      let snapshot_params = JSON.parse(localStorage.getItem("snapshot-params"));
      snapshot_params.push({ user_id, date, teammates_page: true });
      localStorage.setItem(`snapshot-params`, JSON.stringify(snapshot_params));
    },
    getElapsedHours({ total_working_hours, current_working_hours }) {
      let cal_working_hours = (total_working_hours / 60).toFixed(1);
      let cal_current_working_hours = (current_working_hours / 60).toFixed(1);
      return `${cal_working_hours} (${cal_current_working_hours})`;
    },
    redmineFilterHandler(data) {
      Array.isArray(this.rows) ? (this.rows.length = 0) : (this.rows = null);
      this.rows = data;
    },
    ConvertMinutesToTime2(value) {
      if (value) {
        var hours = parseInt(Math.floor(value / 60));
        var minutes = parseInt(Math.floor(value % 60));

        if (hours == 0) {
          return "00" + ":" + (minutes < 10 ? "0" + minutes : minutes);
        } else {
          return (
            (hours < 10 ? "0" + hours : hours) +
            ":" +
            (minutes < 10 ? "0" + minutes : minutes)
          );
        }
      } else {
        return "00:00";
      }
    },
    FilterApply() {
      if (this.selected_months.month != null) {
        let data = {
          selected_months: "",
          filtered_users: "",
          user_organization: "",
        };
        data.selected_months = this.selected_months;
        data.filtered_users = this.reqObj.filtered_users.map((item) => item.id);
        data.user_organization = this.reqObj.user_organization.map(
          (item) => item.id
        );
        // console.log(data);
        user.getSnapshot(data).then((result) => {
          this.rows = result.data.data.data;
          this.rows == null ? (this.nodata = true) : (this.nodata = false);
        });
      }
    },
    userListFilter() {
      let temp = [];
      //   console.log(this.users[0]['users_data']);
      this.users[0]["users_data"].forEach((item) => {
        // console.log("doe ",item.doe);
        if (item.doe !== null) {
          // console.log("if-- ",this.reqObj.searched_date);
          if (
            moment(this.selected_months.month).format("yyyy-MM") <=
            moment(item.doe).format("yyyy-MM")
          ) {
            temp.push(item);
          }
        } else if (item.doe == null && item.is_deleted != true) {
          temp.push(item);
        }
      });
      //   console.log(temp);
      this.active_users_list = [{ label: "Select all", users_data: temp }];
    },
    snapshot() {
      this.rows = [];
      this.FilterApply();
    },
  },
  computed: {
    fromDateForPicker() {
      let date = new Date(this.selected_months.month);
      return new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
>>> .vgt-responsive {
  overflow: visible !important;
}

>>> .vgt-table {
  border-collapse: collapse !important;
}

.vgt-table tbody {
  border: 0px !important;
  border-collapse: collapse !important;
}

>>> .vgt-table thead {
  border-collapse: separate !important;
}

>>> .vgt-table thead tr th {
  position: sticky !important;
  top: 0 !important;
  /* box-shadow: inset 1px 0px -1px red; */
}

>>> .custom-th {
  border-right: 0px !important;
  border-spacing: 20px;
}
</style>
