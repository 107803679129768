<template>
  <div class="">
    <form @submit.prevent="onSubmit()">
      <div class="row">
        <div class="col-md-6 mb-3">
          <label class="form-label mandatory">Quote Text</label>
          <textarea
            rows="1"
            wrap="soft"
            maxlength="250"
            id="quote_text"
            v-model="reqObj.text"
            placeholder="Enter quote text"
            class="form-control"
            required
          ></textarea>
        </div>
        <div class="col-md-6 mb-3">
          <label class="form-label mandatory">Display Date</label>
          <datepicker
            type="date"
            placeholder="yyyy-mm-dd"
            v-model="reqObj.display_date"
            :value="dateval"
            :typeable="true"
            :clear-button="true"
            :calendar-button="true"
            :clear-button-icon="'fa fa-times'"
            :input-class="'border-start-0'"
            :calendar-button-icon="'fa fa-calendar'"
            :bootstrap-styling="true"
            :format="formateDate"
            :disabled-dates="disabledDates"
            required
          ></datepicker>
        </div>
        <div class="col-md-12 mb-3">
          <label class="form-label mandatory">Select Image</label>
          <input
            type="file"
            class="form-control"
            required
            @change="onFileUpload"
            v-if="editid == 0"
          />
          <input
            type="file"
            required
            class="form-control"
            @change="onFileUpload"
            v-if="editid != 0"
          />
        </div>
      </div>
      <div class="text-end mt-4">
        <button class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import user from "@/ajax/user";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import myMixin from "@/mixins/Permission";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

export default {
  components: { Datepicker, UnauthorizedPage },
  mixins: [myMixin],
  data() {
    return {
      permissions: window.localStorage.getItem("permissions"),
      FILE: null,
      dateval: "",
      reqObj: {
        text: "",
        display_date: new Date().toISOString().slice(0, 10),
        img_obj_id: " ",
      },
      img_save_path: "",
      image_type: "quotes",
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
      },
      editid: 0,
    };
  },
  mounted() {
    if (this.permissions.includes("quotes.create")) {
      this.getUserPermissions();
      if (
        typeof this.$route.params.id != "undefined" &&
        this.$route.params.id != 0
      ) {
        // console.log("Update task" + this.$route.params.id);
        this.editid = this.$route.params.id;
        this.getQuotesById(this.editid);
      }
    }
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    onFileUpload(event) {
      if (event.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
        if (event.target.files[0].size < 1000000) {
          this.FILE = event.target.files[0];
          // console.log("this.FILE.type", this.FILE.type)
          // console.log(this.img_save_path)
          this.getPreAssighedUrl();
        } else {
          this.$swal(
            "Error",
            "Select an image whose size is less than 1 MB",
            "warning"
          );
          event.target.value = [];
        }
      } else {
        this.$swal(
          "Error",
          "Only jpg/jpeg and png files are allowed!",
          "warning"
        );
        event.target.value = [];
      }
    },
    onSubmit() {
      if (this.editid == 0) {
        const formdata = new FormData();
        formdata.append("file", this.FILE);
        axios
          .put(this.img_save_path, this.FILE, {
            headers: {
              "Content-Type": "image/png",
            },
          })
          .then((res) => {
            // console.log(res)
          });
        user.CreateQuote(this.reqObj).then((result) => {
          // console.log(result.data);
          if (result.data.code == 200) {
            Swal.fire({
              title: "Success",
              text: "Quote Added Successfully !!!",
              icon: "success",
              timer: 2500,
            });
            this.$emit("close-modal");
          } else {
            Swal.fire({
              title: "Error",
              text: "Failed to Add Quote !!!",
              icon: "error",
              timer: 2500,
            });
            this.$emit("close-modal");
          }
        }).catch((err) => {
          Swal.fire({
            title: "Error",
            text: "Something went wrong !!!",
              icon: "error",
              timer: 2500,
            });
            this.$emit("close-modal");
          });
      } else {
        user.updateQuotes(this.editid, this.reqObj).then((result) => {
          this.$swal("Success", "Quote updated successfully", "success");
          this.$router.push({ name: "Quotes" });
        });
      }
    },

    getPreAssighedUrl() {
      user.getPreAssighedUrl(this.image_type).then((result) => {
        // console.log(result.data.data.url);
        this.reqObj.img_obj_id = result.data.data.object_key;
        this.img_save_path = result.data.data.url;
      });
    },
    getQuotesById(id) {
      user.getQuotesById(id).then((result) => {
        // console.log(result.data.data.image)
        this.reqObj.img_obj_id = result.data.data.image;
        this.reqObj.text = result.data.data.text;
        this.reqObj.display_date = result.data.data.date;
      });
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>
<style scoped>
::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}
::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
