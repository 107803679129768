<template>
  <div>
    <!-- Container for rewards section start -->
    <div class="pt-3 pb-5 px-4 bg-white rounded-4 shadow-sm">
      <h5 class="text-primary mb-4 text-center">This month's earned rewards</h5>

      <!-- Loading spinner -->
      <div
        v-if="loading"
        class="col d-flex justify-content-center align-items-center"
        style="height: 0px"
      >
        <div
          class="spinner-border text-primary"
          style="height: 80px; width: 80px; margin-top: 200px"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <!-- Chart section -->
      <div class="row">
        <div class="col">
          <div class="bg-white" :style="{ height: chartHeight }">
            <canvas id="myChart"></canvas>
          </div>
        </div>
      </div>
    </div>

    <!-- Error message display -->
    <div v-if="error" class="text-danger">{{ error }}</div>
  </div>
</template>

<script>
import Vue from "vue";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";
import user from "@/ajax/user";
import ReadMore from "vue-read-more";
import { Error, FetchError } from "../../Utils/String";
import { mapState } from "vuex";
Vue.use(ReadMore);
Chart.register(ChartDataLabels);

export default {
  data() {
    return {
      data: null,
      error: null,
      filter_data: {
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().endOf("month").format("YYYY-MM-DD"),
      },
      chartHeight: "370px",
      loading: true,
      maxAvailableCount: 0,
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const obj = {
          id: this.currentUser.id,
          start_date: this.filter_data.from,
          end_date: this.filter_data.to,
        };
        user
          .getRewardsSummary(obj)
          .then((res) => {
            this.data = res.data.data;
            // console.log(res.data.data);
            // Calculate the max available count from the data
            this.maxAvailableCount = Math.max(
              ...this.data.map((item) => item.available)
            );
            this.loading = false;
            this.renderChart();
          })
          .catch((error) => {
            console.error(Error, error);
            this.error = FetchError;
            this.loading = false;
          });
      } catch (error) {
        console.error(Error, error);
        this.error = FetchError;
      }
    },

    renderChart() {
      if (!this.data) return;
      const labels = this.data.map((item) => item.name);
      const earnedCounts = this.data.map((item) => item.earned);
      const availableCounts = this.data.map((item) => item.available);
      const remainingCounts = availableCounts.map(
        (available, index) => available - earnedCounts[index]
      );

      const data = {
        labels: labels,
        datasets: [
          {
            label: "Earned",
            backgroundColor: "rgb(72, 208, 167)",
            data: earnedCounts,
            datalabels: {
              color: "black",
            },
          },
          {
            label: "Remaining",
            backgroundColor: "rgb(127, 127, 127,0.2)",
            data: remainingCounts,
            datalabels: {
              color: "black",
            },
          },
        ],
      };

      const config = {
        type: "bar",
        data: data,
        options: {
          indexAxis: "y",
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              stacked: true,
              grid: {
                display: false,
              },
            },
            x: {
              stacked: true,
              max: this.maxAvailableCount,

              grid: {
                display: false,
              },
            },
          },

          plugins: {
            datalabels: {
              display: true,
            },
          },
        },
      };
      const ctx = document.getElementById("myChart");
      if (ctx) {
        if (this.loading || this.error) {
          ctx.parentElement.style.height = "0px"; // Adjust height when loading or error
        } else {
          ctx.parentElement.style.height = this.chartHeight; // Use default height when loaded
        }
        new Chart(ctx, config);
      }
    },
  },
};
</script>

<style>
#myChart {
  height: 100%;
  width: 100%;
}
</style>
