<template>
  <div>
    <div class="row justify-content-center">
      <!-- Loading Spinner -->
      <div v-if="!dashboardData" class="col-md-4 col-sm-6">
        <div
          class="bg-light d-flex flex-column align-items-center"
          style="min-height: 143px; margin-top: 80px"
        >
          <div
            class="spinner-border text-primary mt-3"
            style="height: 80px; width: 80px"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>

      <!-- Data Sections -->
      <div v-if="dashboardData">
        <!--attendance,peoject,asset-->
        <div class="row d-flex flex-wrap my-sm-0 mt-0">
          <!-- Late Count -->
          <div class="col-12 col-md-3 mb-3 mb-md-0">
            <router-link
              to="/monthly-attendance-report"
              class="text-decoration-none"
            >
              <div
                class="shadow-sm bg-white d-flex flex-column ps-2 pt-2 pb-3 position-relative border-4 border-start border-danger rounded-3"
              >
                <h6 class="text-secondary text-start">Late Count</h6>
                <h5
                  class="text-black text-start mt-2 me-5 fw-normal"
                  style="line-height: 2px"
                >
                  {{ dashboardData.data.your.attendance.late_count }}
                </h5>
                <img
                  class="position-absolute bottom-0 end-0"
                  src="@/views/img/Arrow.png"
                  alt="Arrow"
                  style="width: 30px; height: 30px"
                />
              </div>
            </router-link>
          </div>

          <!-- On Time Count -->
          <div class="col-12 col-md-3 mb-3 mb-md-0">
            <router-link
              to="/monthly-attendance-report"
              class="text-decoration-none"
            >
              <div
                class="shadow-sm bg-white d-flex flex-column ps-2 pt-2 pb-3 position-relative border-4 border-start border-success rounded-3"
              >
                <h6 class="text-secondary text-start">On Time Count</h6>
                <h5
                  class="text-black text-start mt-2 me-5 fw-normal"
                  style="line-height: 2px"
                >
                  {{ dashboardData.data.your.attendance.on_time_count }}
                </h5>
                <img
                  class="position-absolute bottom-0 end-0"
                  src="@/views/img/Arrow.png"
                  alt="Arrow"
                  style="width: 30px; height: 30px"
                />
              </div>
            </router-link>
          </div>

          <div class="col-12 col-md-3 mb-3 mb-md-0">
            <!-- Projects -->
            <router-link to="/Projects" class="text-decoration-none">
              <div
                class="shadow-sm bg-white d-flex flex-column ps-2 pt-2 pb-3 position-relative border-4 border-start border-primary rounded-3"
              >
                <h6 class="text-start text-secondary">Projects</h6>
                <h5
                  class="text-black text-start mt-2 me-5 fw-normal"
                  style="line-height: 2px"
                >
                  {{ dashboardData.data.your.project_count }}
                </h5>
                <img
                  class="position-absolute bottom-0 end-0"
                  src="@/views/img/Arrow.png"
                  alt="Arrow"
                  style="width: 30px; height: 30px"
                />
              </div>
            </router-link>
          </div>

          <!-- Assets -->
          <div class="col-12 col-md-3 mb-3 mb-md-0">
            <!-- <router-link :to="userprofile" class="text-decoration-none"> -->
            <router-link
              :to="`/prof/${currentUser.id}?tab=assets`"
              class="text-decoration-none"
            >
              <div
                class="shadow-sm bg-white d-flex flex-column ps-2 pt-2 pb-3 position-relative border-4 border-start border-warning rounded-3"
              >
                <h6 class="text-start text-secondary">Assets</h6>
                <h5
                  class="text-black text-start mt-2 me-5 fw-normal"
                  style="line-height: 2px"
                >
                  {{ dashboardData.data.your.asset_count }}
                </h5>
                <img
                  class="position-absolute bottom-0 end-0"
                  src="@/views/img/Arrow.png"
                  alt="Arrow"
                  style="width: 30px; height: 30px"
                />
              </div>
            </router-link>
          </div>
        </div>
        <!--Activities  -->
        <div class="col">
          <router-link to="/reports" class="text-decoration-none">
            <div
              class="shadow-sm bg-white ps-3 pt-2 pb-3 pe-5 position-relative border-4 border-start border-success rounded-3 mt-3"
            >
              <h5 class="text-start text-secondary">Activities</h5>
              <div class="d-flex flex-wrap justify-content-between gap-3">
                <div class="text-start">
                  <h6 class="text-secondary fw-normal">Total</h6>
                  <h5
                    class="text-black mt-3 fw-normal"
                    style="line-height: 2px"
                  >
                    {{ dashboardData.data.your.reports.total_working_hours }}
                  </h5>
                </div>
                <div class="text-start">
                  <h6 class="text-secondary fw-normal">Reported</h6>
                  <h5
                    class="text-black mt-3 fw-normal"
                    style="line-height: 2px"
                  >
                    {{ dashboardData.data.your.reports.total_reported_hours }}
                  </h5>
                </div>
                <div class="text-start">
                  <h6 class="text-secondary fw-normal">Not Reported</h6>
                  <h5
                    class="text-black mt-3 fw-normal"
                    style="line-height: 2px"
                  >
                    {{
                      dashboardData.data.your.reports.total_not_reported_hours
                    }}
                  </h5>
                </div>
                <div class="text-start">
                  <h6 class="text-secondary fw-normal">Approved</h6>
                  <h5
                    class="text-black mt-3 fw-normal"
                    style="line-height: 2px"
                  >
                    {{ dashboardData.data.your.reports.total_approved_hours }}
                  </h5>
                </div>
                <div class="text-start">
                  <h6 class="text-secondary fw-normal">Not Approved</h6>
                  <h5
                    class="text-black mt-3 fw-normal"
                    style="line-height: 2px"
                  >
                    {{
                      dashboardData.data.your.reports.total_not_approved_hours
                    }}
                  </h5>
                </div>
                <div class="text-start">
                  <h6 class="text-secondary fw-normal">Rejected</h6>
                  <h5
                    class="text-black mt-3 fw-normal"
                    style="line-height: 2px"
                  >
                    {{ dashboardData.data.your.reports.total_rejected_hours }}
                  </h5>
                </div>
              </div>
              <img
                class="position-absolute bottom-0 end-0"
                src="@/views/img/Arrow.png"
                alt="Arrow"
                style="width: 30px; height: 30px"
              />
            </div>
          </router-link>
        </div>

        <div class="row">
          <div
            class="col-12 col-md-6 mt-2 d-flex flex-md-row flex-column align-items-center"
          >
            <!-- Leaves -->
            <router-link
              to="/leaves-application"
              class="text-decoration-none w-100"
            >
              <div
                class="shadow-sm bg-white ps-3 pe-1 pt-2 pb-3 pe-3 position-relative border-4 border-start border-info mt-2 rounded-3 w-100"
              >
                <h5 class="text-start text-secondary">Leaves</h5>
                <div class="d-flex flex-wrap flex-row justify-content-between">
                  <div class="text-center text-md-start">
                    <h6 class="text-secondary fw-normal">Total</h6>
                    <h5
                      class="text-black mt-3 fw-normal"
                      style="line-height: 2px"
                    >
                      {{ dashboardData.data.your.leaves.total_leaves }}
                    </h5>
                  </div>
                  <div class="text-center text-md-start">
                    <h6 class="text-secondary fw-normal">Applied</h6>
                    <h5
                      class="text-black mt-3 fw-normal"
                      style="line-height: 2px"
                    >
                      {{ dashboardData.data.your.leaves.total_applied_leaves }}
                    </h5>
                  </div>
                  <div class="text-center text-md-start">
                    <h6 class="text-secondary fw-normal">Approved</h6>
                    <h5
                      class="text-black mt-3 fw-normal"
                      style="line-height: 2px"
                    >
                      {{ dashboardData.data.your.leaves.total_approved_leaves }}
                    </h5>
                  </div>
                  <div class="text-center text-md-start px-1">
                    <h6 class="text-secondary fw-normal">Rejected</h6>
                    <h5
                      class="text-black mt-3 fw-normal"
                      style="line-height: 2px"
                    >
                      {{ dashboardData.data.your.leaves.total_rejected_leaves }}
                    </h5>
                  </div>
                </div>
                <img
                  class="position-absolute bottom-0 end-0"
                  src="@/views/img/Arrow.png"
                  alt="Arrow"
                  style="width: 30px; height: 30px"
                />
              </div>
            </router-link>
          </div>

          <!-- Leave Status -->
          <div
            class="col-12 col-md-6 mt-2 d-flex flex-column align-items-center"
          >
            <router-link to="/leaves-status" class="text-decoration-none w-100">
              <div
                class="shadow-sm bg-white ps-3 pt-2 pb-3 pe-3 position-relative border-4 border-start border-warning rounded-3 mt-2 w-100"
              >
                <h5 class="text-start text-secondary">Leaves Status</h5>
                <div class="d-flex flex-wrap flex-row justify-content-between">
                  <!-- <div class="text-center text-md-start px-1">
                    <h6 class="text-secondary fw-normal">Total</h6>
                    <h5
                      class="text-black mt-3 fw-normal"
                      style="line-height: 2px"
                    >
                      {{ dashboardData.data.your.leave_status.total }}
                    </h5>
                  </div> -->
                  <div class="text-center text-md-start px-1">
                    <h6 class="text-secondary fw-normal">Opening</h6>
                    <h5
                      class="text-black mt-3 fw-normal"
                      style="line-height: 2px"
                    >
                      {{ dashboardData.data.your.leave_status.opening }}
                    </h5>
                  </div>
                  <div class="text-center text-md-start px-1">
                    <h6 class="text-secondary fw-normal">Credit</h6>
                    <h5
                      class="text-black mt-3 fw-normal"
                      style="line-height: 2px"
                    >
                      {{ dashboardData.data.your.leave_status.credit }}
                    </h5>
                  </div>
                  <div class="text-center text-md-start px-1">
                    <h6 class="text-secondary fw-normal">Debit</h6>
                    <h5
                      class="text-black mt-3 fw-normal"
                      style="line-height: 2px"
                    >
                      {{ dashboardData.data.your.leave_status.debit }}
                    </h5>
                  </div>
                  <div class="text-center text-md-start px-1">
                    <h6 class="text-secondary fw-normal">Closing</h6>
                    <h5
                      class="text-black mt-3 fw-normal"
                      style="line-height: 2px"
                    >
                      {{ dashboardData.data.your.leave_status.closing }}
                    </h5>
                  </div>
                </div>
                <img
                  class="position-absolute bottom-0 end-0"
                  src="@/views/img/Arrow.png"
                  alt="Arrow"
                  style="width: 30px; height: 30px"
                />
              </div>
            </router-link>
          </div>
        </div>
        <!-- Teams -->
        <div v-if="currentUser.has_subordinates == true">
          <div class="row">
            <div class="d-flex align-items-center justify-content-start my-3">
              <h5 class="mb-0 pe-2">Team</h5>
              <hr class="w-100" />
            </div>
            <!-- Pending Actions -->
            <div class="col-12 col-md-6 d-flex flex-column">
              <div
                class="shadow-sm bg-white ps-3 pt-2 pb-3 position-relative border-4 border-start border-danger rounded-3"
              >
                <h5 class="text-start text-secondary">Pending Actions</h5>
                <div
                  class="d-flex flex-wrap justify-content-lg-between justify-content-center"
                >
                  <div class="row w-100">
                    <div class="col text-center text-md-start">
                      <h6 class="text-secondary fw-normal">Total</h6>
                      <h5
                        class="text-black text-center text-md-start mt-3 fw-normal"
                        style="line-height: 2px"
                      >
                        {{ dashboardData.data.team.pending_actions.total }}
                      </h5>
                    </div>
                    <div class="col text-center text-md-start">
                      <router-link
                        to="/update-attendance"
                        class="text-decoration-none"
                      >
                        <h6 class="text-secondary fw-normal">Update</h6>
                        <h5
                          class="text-black text-center text-md-start mt-3 fw-normal"
                          style="line-height: 2px"
                        >
                          {{
                            dashboardData.data.team.pending_actions
                              .update_attendance_request
                          }}
                        </h5>
                      </router-link>
                    </div>
                    <div class="col text-center text-md-start">
                      <router-link
                        to="/leaves-application"
                        class="text-decoration-none"
                      >
                        <h6 class="text-secondary fw-normal">Leaves</h6>
                        <h5
                          class="text-black text-center text-md-start mt-3 fw-normal"
                          style="line-height: 2px"
                        >
                          {{ dashboardData.data.team.pending_actions.leaves }}
                        </h5>
                      </router-link>
                    </div>
                    <div class="col text-center text-md-start">
                      <router-link
                        to="/reports?tab=tm_ts"
                        class="text-decoration-none"
                      >
                        <h6 class="text-secondary fw-normal">Reports</h6>
                        <h5
                          class="text-black text-center text-md-start mt-3 fw-normal"
                          style="line-height: 2px"
                        >
                          {{ dashboardData.data.team.pending_actions.reports }}
                        </h5>
                      </router-link>
                    </div>
                  </div>
                </div>
                <router-link to="/reports" class="text-decoration-none">
                  <img
                    class="position-absolute bottom-0 end-0"
                    src="@/views/img/Arrow.png"
                    alt="Arrow"
                    style="width: 30px; height: 30px"
                  />
                </router-link>
              </div>
            </div>

            <!-- Attendance Team Mates -->
            <div class="col-12 col-md-6 d-flex flex-column">
              <router-link to="/attendance" class="text-decoration-none">
                <div
                  class="shadow-sm bg-white ps-3 pt-2 pb-3 position-relative border-4 border-start border-secondary rounded-3 mt-3 mt-md-0"
                >
                  <h5 class="text-start text-secondary">
                    Attendance Team Mates
                  </h5>
                  <div
                    class="d-flex flex-wrap justify-content-lg-between justify-content-start"
                  >
                    <div class="row w-100">
                      <div class="col text-center text-md-start">
                        <h6 class="text-secondary fw-normal">Total</h6>
                        <h5
                          class="text-black text-center text-md-start mt-3 fw-normal"
                          style="line-height: 2px"
                        >
                          {{
                            dashboardData.data.team.attendance.total_teammates
                          }}
                        </h5>
                      </div>
                      <div class="col text-center text-md-start">
                        <h6 class="text-secondary fw-normal">Present</h6>
                        <h5
                          class="text-black text-center text-md-start mt-3 fw-normal"
                          style="line-height: 2px"
                        >
                          {{
                            dashboardData.data.team.attendance
                              .total_present_teammates
                          }}
                        </h5>
                      </div>
                      <div class="col text-center text-md-start px-1">
                        <h6 class="text-secondary fw-normal">Absent</h6>
                        <h5
                          class="text-black text-center text-md-start mt-3 fw-normal"
                          style="line-height: 2px"
                        >
                          {{
                            dashboardData.data.team.attendance
                              .total_absent_teammates
                          }}
                        </h5>
                      </div>
                      <div class="col text-center text-md-start">
                        <h6 class="text-secondary fw-normal">On Leave</h6>
                        <h5
                          class="text-black text-center text-md-start mt-3 fw-normal"
                          style="line-height: 2px"
                        >
                          {{
                            dashboardData.data.team.attendance
                              .total_not_logged_in_teammates
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <img
                    class="position-absolute bottom-0 end-0"
                    src="@/views/img/Arrow.png"
                    alt="Arrow"
                    style="width: 30px; height: 30px"
                  />
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import { Error } from "../../Utils/String";
import { RouterLink } from "vue-router";

export default {
  name: "DashboardSummary",

  data() {
    return {
      dashboardData: null,
      loading: true,
    };
  },
  methods: {
    getDashboardSummary() {
      user
        .getDashboardSummary()
        .then((result) => {
          this.dashboardData = result.data;
          this.loading = false;
          // console.log(result.data);
        })
        .catch((error) => {
          console.error(Error, error);
          // this.loading = false;
        });
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    ...mapActions("userStore", ["setCurrentUser"]),
    ...mapState("common", ["currentPage"]),
  },
  mounted() {
    this.getDashboardSummary();
  },
};
</script>

<style></style>
