<template>
  <div>
    <!-- Begin Page Content -->
    <div class="" v-if="permissions.includes('hierarchy.read')">
      <div class="card shadow-sm border-0 my-4">
        <div class="card-body">
          <div
            class="d-flex justify-content-between align-items-center px-2 pb-3"
          >
            <h4 class="text-primary m-0">Org Chart</h4>
          </div>
          <div class="text-center p-5" v-if="orgChartData.length == 0">
            <div
              class="spinner spinner-border text-primary"
              style="height: 4rem; width: 4rem"
            ></div>
          </div>
          <div
            class="overflow-x-auto pb-5 bg-white"
            id="customScrollBar"
            v-else
          >
            <div
              class="vstack position-fixed z-3 m-2"
              style="height: auto; width: 50px"
            >
              <button class="zoombtn" @click="zoomInHandler">+</button>
              <button class="zoombtn" @click="zoomOutHandler">-</button>
            </div>
            <div
              class="tree mx-auto"
              style="width: max-content"
              ref="tree-container"
            >
              <RecursiveList :treeData="orgChartData"></RecursiveList>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <UnauthorizedPage></UnauthorizedPage>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
export default {
  components: {
    UnauthorizedPage,
  },
  data() {
    return {
      orgChartData: [],
      zoomLevel: 1,
      permissions: window.localStorage.getItem("permissions"),
    };
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    zoomInHandler() {
      this.zoomLevel += 0.2;
      this.applyZoom();
    },
    zoomOutHandler() {
      this.zoomLevel -= 0.2;
      this.zoomLevel < 0.8 ? (this.zoomLevel = 0.8) : "";
      this.applyZoom();
    },
    applyZoom() {
      const treeContainer = this.$refs["tree-container"];
      treeContainer.style.transform = `scale(${this.zoomLevel})`;
    },
    getOrgChartData() {
      user
        .getHierarchyOfUser()
        .then((res) => {
          this.orgChartData = res.data.data;
        })
        .catch((err) => console.error(err));
    },
  },
  mounted() {
    this.setCurrentPage("OrgChart");
    this.getOrgChartData();
  },
};
</script>

<style>
/*Now the CSS*/

.zoombtn {
  background-color: rgba(0, 0, 0, 0.1);
  color: black;
  border: 0px;
  height: 30px;
  width: 30px;
  font-size: 18px;
}

.zoombtn:hover {
  background-color: whitesmoke;
  color: black;
  border: 0px;
}

.tree {
  transform: scale(1);
  transition: 0.2s ease-in-out;
}

.tree ul {
  padding-top: 40px;
  position: relative;
  /* border:1px dotted red; */
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  /* width:100px; */
}

.tree li {
  /* width:50px; */
  /* border:1px dotted blue; */
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and 
right connector from last child*/
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 40px;
}

.tree li a {
  border: 1px solid #ccc;
  padding: 5px 8px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover,
.tree li a:hover + ul li a {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}

/*Connector styles on hover*/
.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before {
  border-color: #94a0b4;
}

/*Thats all. I hope you enjoyed it.
Thanks :)*/
</style>
