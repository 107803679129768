<template>
  <!-- Begin Page Content -->
  <div class="">
    <div
      class="card my-4 border-0"
      v-if="permissions.includes('sub_clients.read')"
    >
      <div class="card-body">
        <div
          class="d-flex gap-md-0 gap-2 justify-content-between align-items-center px-2 pb-3 flex-wrap"
        >
          <h4 class="text-primary m-0 order-md-0 order-1">Sub Clients</h4>
          <div
            class="order-md-0 order-3 mx-md-0 mx-auto mt-md-0 mt-2"
            v-if="
              permissions.includes('clients.read') &&
              permissions.includes('sub_clients.read')
            "
          >
            <div class="btn-group" role="group">
              <router-link
                :to="{ name: 'Clients' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'Clients'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
              >
                Clients
              </router-link>
              <router-link
                :to="{ name: 'SubClients' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'SubClients'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
              >
                SubClients
              </router-link>
            </div>
          </div>
          <button
            class="btn btn-primary btn-sm order-md-0 order-2"
            v-if="
              permissions.includes('sub_clients.write') &&
              permissions.includes('sub_clients.add')
            "
            @click="subclientModalHandler('add')"
          >
            Add Sub Client
          </button>
        </div>
        <div v-if="permissions.includes('sub_clients.read')" class="p-2">
          <div class="">
            <vue-good-table
              styleClass="vgt-table no-border"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="FilteredRecords"
              :isLoading.sync="isLoading"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
              :rows="rows"
              :columns="columns"
            >
              <template slot="table-row" scope="props">
                <template v-if="props.column.label == 'Action'">
                  <div
                    v-if="permissions.includes('sub_clients.write')"
                    class="d-flex justify-content-center align-items-center gap-3 p-2"
                  >
                    <button
                      v-if="permissions.includes('sub_clients.write')"
                      class="btn-pencil"
                      @click="subclientModalHandler('update', props.row.id)"
                    >
                      <i class="fa fa-pencil fs-5"></i>
                    </button>
                    <button
                      v-if="permissions.includes('sub_clients.delete')"
                      class="btn-trash"
                      @click="deleteSubClient(props.row.id)"
                    >
                      <i class="fa fa-trash fs-5"></i>
                    </button>
                  </div>
                </template>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                  :serverParams="serverParams"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="SubClientModal"
      role="dialog"
      aria-hidden="true"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="text-dark font-weight-bold m-0"
              v-text="modal_title"
            ></h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="overflow-y: auto">
            <AddSubClient
              @close-modal="subclientModalHandler('close')"
              v-if="modal_title == 'Update SubClient'"
              :subclient_id="subclient_id"
            ></AddSubClient>
            <AddSubClient
              @close-modal="subclientModalHandler('close')"
              v-if="modal_title == 'Add SubClient'"
            ></AddSubClient>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!permissions.includes('sub_clients.read')">
      <UnauthorizedPage></UnauthorizedPage>
    </div>
  </div>
  <!-- End of Main Content -->
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import AddSubClient from "./AddSubClient.vue";
import axios from "axios";
import myMixin from "@/mixins/Permission";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import CustomPagination from "../../components/CustomPagination.vue";

Vue.use(VueSweetalert2);

// import VueAxios from 'vue-axios';
// Vue.use(VueAxios,axios)

export default {
  name: "Dashboard",
  mixins: [myMixin],
  components: { UnauthorizedPage, AddSubClient, CustomPagination },
  data: function () {
    return {
      permissions: window.localStorage.getItem("permissions"),
      currentPage: "",
      isLoading: false,
      columns: [
        {
          label: "Client",
          field: "name",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Enter Client name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Sub Client",
          field: "subclientname",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Sub Client Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Contact Person",
          field: "contactPerson",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Contact Person", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Contact Email",
          field: "contactEmail",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Contact Email", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: " Manager",
          field: "first_name",
          type: "value",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Manager name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "disable-sorting custom-th",
          tdClass: "custom-td text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      FilteredRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      perpage_list: [100, 200, 300, 400, 500, "All"],
      modal_title: null,
      subclient_id: null,
    };
  },
  mounted() {
    this.setCurrentPage("Clients");
    $("#SubClientModal").on("hidden.bs.modal", this.hideModalEventHandler);
    if (this.permissions.includes("sub_clients.read")) {
      // this.getAllSubClient();
      this.getUserPermissions();
    }
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    hideModalEventHandler() {
      this.modal_title = null;
      this.subclient_id = null;
      $("#SubClientModal").modal("hide");
    },
    subclientModalHandler(action, id) {
      if (action == "add") {
        this.modal_title = "Add SubClient";
        $("#SubClientModal").modal("show");
      } else if (action == "update") {
        this.modal_title = "Update SubClient";
        this.subclient_id = id;
        $("#SubClientModal").modal("show");
      } else {
        $("#SubClientModal").modal("hide");
        this.search_subclient();
      }
    },
    getAllSubClient() {
      user.getAllSubClient(this.reqObj).then((result) => {
        this.rows = [];
        this.rows = result.data.data.data;
        this.totalRecords = result.data.data.total;
      });
    },
    search_subclient() {
      var fields = {
        filters: this.serverParams.columnFilters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      user.search_subclient(fields).then((result) => {
        this.rows = [];
        this.reqObj = result.data.data.data;
        this.rows = result.data.data.data;
        this.FilteredRecords = result.data.data.total;
      });
    },
    deleteSubClient(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          // this.$swal('Deleted', 'You successfully delete this client', 'success')
          user.deleteSubClient(id).then((result) => {
            if (result.data.code == 424) {
              this.$swal(result.data.data, result.data.message, "warning");
            }
            if (result.data.code == 200) {
              this.$swal(result.data.data, result.data.message, "success");
            }
            this.search_subclient();
          });
        }
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.search_subclient();
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      this.search_subclient();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.search_subclient();
    },

    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      // console.log("params",params);
      {
        field: "project_name";
        field: "slug";
        field: "first_name";
      }
      this.serverParams.page = 1;
      var filtersPr = JSON.parse(JSON.stringify(this.serverParams));
      var filters = filtersPr.columnFilters;
      // console.log("filters",filters);
      this.search_subclient();
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
</style>
