<template>
  <div class="">
    <div v-if="currentUser.user_role == 1" class="card shadow-sm border-0 my-4">
      <div class="card-body">
        <div
          class="d-flex justify-content-between align-items-center px-2 pb-3"
        >
          <h4 class="text-primary m-0">Invoice's</h4>
          <router-link
            tag="button"
            :to="{ name: 'AddInvoice' }"
            class="btn btn-primary"
          >
            Add Invoice
          </router-link>
        </div>
        <div class="p-2">
          <vue-good-table
            styleClass="vgt-table no-border"
            mode="remote"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            :totalRows="total_records"
            :rows="invoice_rows"
            :columns="invoice_columns"
            :pagination-options="{
              enabled: true,
              perPageDropdown: perpage_list,
            }"
          >
            <template
              slot="column-filter"
              slot-scope="{ column, updateFilters }"
            >
              <span v-if="column.field == 'invoice_date'">
                <datepicker
                  :typeable="true"
                  placeholder="yyyy-mm-dd"
                  @input="
                    (e) =>
                      updateFilters(
                        column,
                        e
                          ? e.toISOString().substring(0, 10)
                          : updateFilters(column, '')
                      )
                  "
                />
              </span>
              <span v-else-if="column.field == 'price'">
                <input
                  type="number"
                  min="0"
                  class="form-control rounded-1"
                  style="height: 25.47px; font-size: 14px"
                  placeholder="price"
                  @input="(e) => updateFilters(column, e.target.value)"
                />
              </span>
              <span v-else-if="column.field == 'total'">
                <input
                  type="number"
                  min="0"
                  class="form-control rounded-1"
                  style="height: 25.47px; font-size: 14px"
                  placeholder="total"
                  @input="(e) => updateFilters(column, e.target.value)"
                />
              </span>
              <span v-else-if="column.field == 'GST'">
                <input
                  type="number"
                  min="0"
                  class="form-control rounded-1"
                  style="height: 25.47px; font-size: 14px"
                  placeholder="GST"
                  @input="(e) => updateFilters(column, e.target.value)"
                />
              </span>
              <span v-else-if="column.field == 'total_assets'">
                <input
                  type="number"
                  min="0"
                  class="form-control rounded-1"
                  style="height: 25.47px; font-size: 14px"
                  placeholder="No of assets"
                  @input="(e) => updateFilters(column, e.target.value)"
                />
              </span>
            </template>
            <template slot="table-row" slot-scope="props">
              <span
                class="d-flex justify-content-center align-items-center p-2 gap-3"
                v-if="props.column.field == 'action'"
              >
                <router-link
                  tag="button"
                  :to="'/update-invoice/' + props.row.id"
                  class="btn btn-pencil"
                >
                  <i class="fa fa-pencil fs-5"></i>
                </router-link>
                <button
                  class="btn btn-trash"
                  @click="deleteInvoice(props.row.id)"
                >
                  <i class="fa fa-trash fs-5"></i>
                </button>
              </span>
            </template>
            <template slot="pagination-bottom" slot-scope="props">
              <CustomPagination
                class="p-2"
                :pageTabs="4"
                :serverParams="serverParams"
                :total="props.total"
                :pageChanged="props.pageChanged"
                :perPageChanged="props.perPageChanged"
              ></CustomPagination>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
    <div v-else>
      <h2 class="text-muted">Unauthorized Access</h2>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import CustomPagination from "../../components/CustomPagination.vue";
import Datepicker from "vuejs-datepicker";
import myMixin from "@/mixins/Permission";
export default {
  mixins: [myMixin],
  components: {
    CustomPagination,
    Datepicker,
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  mounted() {
    this.setCurrentPage("Assets");
    this.getUserPermissions();
    this.getInvoices();
  },
  data() {
    return {
      invoice_rows: [],
      invoice_columns: [
        {
          label: "Invoice Number",
          field: "invoice_no",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true,
            trigger: "enter",
          },
        },
        {
          label: "Invoice Date",
          field: "invoice_date",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true,
            trigger: "enter",
          },
        },
        {
          label: "Price",
          field: "price",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true,
            trigger: "enter",
          },
        },
        {
          label: "Total",
          field: "total",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true,
            trigger: "enter",
          },
        },
        {
          label: "GST",
          field: "GST",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true,
            trigger: "enter",
          },
        },
        {
          label: "Paid",
          field: "paid",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true,
            filterDropdownItems: ["true", "false"],
            trigger: "enter",
          },
        },
        {
          label: "No. of Assets",
          field: "total_assets",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true,
            trigger: "enter",
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
      ],
      serverParams: {
        filters: {},
        page: 1,
        perpage: 100,
        setCurrentPage: 1,
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
      },

      total_records: null,
      perpage_list: [100, 200, 300, 400, 500],
    };
  },
  methods: {
    ...mapActions("common", ["setCurrentPage"]),
    deleteInvoice(id) {
      Swal.fire({
        title: "Do you want to delete invoice ?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        icon: "warning",
      }).then((res) => {
        if (res.isConfirmed) {
          user
            .deleteInvoice(id)
            .then((res) => {
              Swal.fire({
                title: res.data.message,
                icon: "success",
              });
              this.getInvoices();
            })
            .catch((err) => {
              Swal.fire({
                title: err.response.data.detail,
                icon: "error",
              });
            });
        }
      });
    },
    getInvoices() {
      user.getInvoices(this.serverParams).then((res) => {
        this.invoice_rows = res.data.data.data;
        this.total_records = res.data.data.total;
      });
    },
    onColumnFilter(params) {
      var newObj = { filters: Object.assign({}) };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.filters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      this.serverParams.page = 1;
      this.getInvoices();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getInvoices();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.serverParams.page = 1;
      this.getInvoices();
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.getInvoices();
    },
  },
};
</script>
