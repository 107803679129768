<template>
  <div class="my-3">
    <!-- <div class="pt-1 pb-4 bg-white border"  style="border-radius:8px;" v-if="filters!==null"> -->
    <div
      class="pt-1 pb-3 bg-white shadow-sm border border-light"
      style="border-radius: 8px"
    >
      <!-- <div class="py-3 px-5" v-if="show"> -->
      <div class="pt-3 px-3">
        <!-- Code for filter column selection -->
        <div
          class="d-flex justify-content-md-end align-items-center align-items-start mb-3"
        >
          <h6 class="text-nowrap font-weight-bold pe-3" style="font-size: 16px">
            Select Filter :
          </h6>
          <select
            id="filter_selection"
            class="form-control"
            v-model="filter_dropdown"
            @change="columnFilterHandler"
          >
            <option value="" selected>Select Filters</option>
            <option
              :value="key"
              v-for="(item, key, count) in filters"
              :key="count"
              v-text="key"
              :disabled="item.disabled"
            ></option>
          </select>
        </div>
      </div>
      <div
        class="my-md-0 mb-3 px-3"
        v-for="(item, key, count) in selected_filters"
        :key="count"
      >
        <div
          class="d-flex flex-md-row flex-column justify-content-stretch align-items-center g-0 gap-2 mb-2"
          v-if="item.disabled"
        >
          <!-- Code for checkbox -->
          <div
            class="col-md-2 col-12 pe-0 d-flex justify-content-between align-items-center"
          >
            <input
              type="checkbox"
              class="mx-auto d-block"
              :disabled="!showDeleteBtn(key)"
              @change="checkboxInputHandler(item)"
              :checked="item.checked"
            />
            <input
              type="checkbox"
              title="Toggle Hierarchy View"
              v-model="item.hviewValue"
              v-if="item.showHview"
              class="mx-auto ms-2 d-block border"
              id=""
              style="accent-color: gold"
            />
            <h6
              v-text="key"
              class="text-dark fw-bold text-uppercase flex-grow-1 mb-0"
              :class="
                item.showHview
                  ? ' ps-md-2 ps-2 ms-md-0'
                  : ' ps-md-4 ps-2 ms-md-2'
              "
              style="font-size: 14px"
            ></h6>
            <span
              class="d-md-none d-block"
              id="crossBtn"
              v-if="showDeleteBtn(key)"
              @click="deleteFilter(key)"
              >&times;</span
            >
          </div>

          <!-- Code for filter type selection -->
          <div class="col-md-3 col-12 m-0 p-0">
            <!-- {{ item.options_error_msg }} -->
            <!-- <small class="error-msg" v-text="item.options_error_msg"></small> -->
            <multiselect
              :disabled="item.lock_options"
              required
              class="custom_multiselect"
              :class="item.options_error_msg ? 'validation-true' : ''"
              :options="item.options"
              v-model="item.selected_options"
              @select="renderMultiselect($event, item)"
              @remove="$forceUpdate()"
              placeholder="Filter options"
            >
            </multiselect>
          </div>

          <!-- Code for all types of selection like str:inputbox,list:dropdown,date:datepicker etc.... -->

          <div class="col-md-3 col-12">
            <!-- <small class="error-msg" v-text="item.values_error_msg"></small> -->
            <multiselect
              v-if="item.type === 'list'"
              class="custom_multiselect"
              :options="item.values"
              v-model="item.selected_values"
              :class="item.values_error_msg ? 'validation-true' : ''"
              @select="renderMultiselect($event, item)"
              @remove="$forceUpdate()"
              :placeholder="'Select ' + key"
              label="name"
              track-by="id"
            ></multiselect>

            <multiselect
              v-if="item.type === 'mlist' && key == 'sub_client'"
              class="custom_multiselect"
              :options="item.values"
              v-model="item.selected_values"
              :class="item.values_error_msg ? 'validation-true' : ''"
              :multiple="true"
              :close-on-select="false"
              @select="renderMultiselect($event, item)"
              @remove="$forceUpdate()"
              :placeholder="'Select ' + key"
              label="sname"
              track-by="id"
            ></multiselect>

            <multiselect
              v-if="item.type === 'mlist' && key != 'sub_client'"
              class="custom_multiselect"
              :options="item.values"
              v-model="item.selected_values"
              :class="item.values_error_msg ? 'validation-true' : ''"
              :multiple="true"
              :close-on-select="false"
              @select="renderMultiselect($event, item)"
              @remove="$forceUpdate()"
              :placeholder="'Select ' + key"
              label="name"
              track-by="id"
            ></multiselect>

            <input
              v-else-if="item.type === 'str'"
              @keypress.enter="applyFilter"
              @input="inputHandler(item)"
              type="text"
              class="filter_field form-control"
              v-model="item.selected_values"
              :placeholder="'Filter ' + key"
              :class="item.values_error_msg ? 'validation-true-dp' : ''"
            />

            <div
              class="m-0 p-0"
              v-else-if="item.type === 'date'"
              v-show="show_datepicker"
            >
              <flatPickr
                class="filter_field form-control bg-white"
                :config="date_config"
                ref="date_flatpickr"
                placeholder="Select date"
                value=""
                @on-change="getPickerDate($event, item)"
                :class="item.values_error_msg ? 'validation-true-dp' : ''"
              ></flatPickr>
            </div>

            <div class="m-0 p-0" v-else-if="item.type === 'time'">
              <flatPickr
                class="filter_field form-control bg-white"
                :config="time_config"
                ref="time_flatpickr"
                value=""
                @on-change="getPickerTime($event, item)"
                @on-ready="item.selected_values = [time_config.defaultDate]"
                :class="item.values_error_msg ? 'validation-true-dp' : ''"
              ></flatPickr>
            </div>

            <div class="m-0 p-0" v-else-if="item.type === 'month'">
              <datepicker
                :minimumView="'month'"
                ref="month_picker"
                :maximumView="'year'"
                class=""
                @selected="getPickedMonth($event, item)"
                :format="'yyyy-MM'"
                :disabled-dates="disabledMonths"
                :bootstrap-styling="true"
              ></datepicker>
            </div>

            <!-- <input v-else @keypress.enter="applyFilter" type="text" class="form-control"> -->
          </div>

          <div class="col-md-3 border-0 d-md-block d-none">
            <span
              class=""
              id="crossBtn"
              v-if="showDeleteBtn(key)"
              @click="deleteFilter(key)"
              >&times;</span
            >
          </div>
        </div>
      </div>
      <div class="row g-0">
        <!-- <div class="mt-1 offset-md-5 d-flex col-md-3 col-12 gap-2 justify-content-md-end justify-cotnent-center align-items-center px-0" v-if="showButtons()"> -->
        <div
          class="col-md-3 mt-md-1 offset-md-5 col-12 d-flex justify-content-md-end justify-content-center align-items-center gap-2"
          v-if="showButtons()"
        >
          <button
            class="btn btn-primary btn-sm py-1"
            style="width: 5rem"
            @click="applyFilter"
          >
            Filter
          </button>
          <button
            class="btn btn-outline-dark btn-sm"
            style="width: 5rem"
            @click="resetFilter"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="bg-white border text-center p-3" style="border-radius:8px"  v-else>
      <div class="spinner-border text-primary" style="width:3rem;height:3rem"></div>
    </div> -->
  </div>
</template>

<script>
import user from "@/ajax/user";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import multiselect from "vue-multiselect";
import EventBus from "@/helpers/event-bus";
import myMixin from "@/mixins/Permission";
import Datepicker from "vuejs-datepicker";
import { Swal } from "sweetalert2/dist/sweetalert2";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    flatPickr,
    multiselect,
    Datepicker,
  },
  mixins: [myMixin],
  data() {
    return {
      filters: null,
      selected_filters: {},
      filter_dropdown: "",
      date_config: {},
      time_config: {},
      month_config: {},
      data_not_loaded: "",
      data_load_msg: "",
      no_internet: "",
      disabled: false,
      summary: "",
      pass_validation: false,
      show_datepicker: true,
      current_user_index: null,
      disabledMonths: {
        from: new Date(),
      },
      // show:true
    };
  },
  // inject: ["serverParams"],
  props: {
    page: {
      type: String,
    },
    set_filters: {
      type: Object,
      default: null,
    },
    serverParams: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    ...mapState("common", ["currentPage"]),
  },
  methods: {
    checkboxInputHandler(item) {
      item.checked = !item.checked;
      this.$forceUpdate();
    },
    inputHandler(item) {
      // console.log(item);
      item.values_error_msg ? (item.values_error_msg = "") : "";
      this.$forceUpdate();
    },
    showDeleteBtn(key) {
      // let check=true;
      if (this.page === "tm_report") {
        if (key == "date" || key == "status") {
          // console.log("returned ---------- false")
          return false;
        }
      }

      if (this.page === "snapshot_report") {
        return key != "month";
      }
      // this.$forceUpdate();
      return true;
    },
    // To re-render the redmine filter component on change in multiselect
    renderMultiselect(event, item) {
      this.$forceUpdate();
      this.optionEventHandler(item.type, event, item);
    },

    // To hide or show the filter and reset button
    showButtons() {
      let bool = Object.values(this.selected_filters).some(
        (item) => item.disabled === true
      );
      return bool;
    },

    filterUserByDoe() {
      if (this.page == "tm_report") {
        // console.log(Object.keys(this.selected_filters));
        if (Object.keys(this.selected_filters).includes("users")) {
          // Re assigning the users list
          this.selected_filters.users.values = structuredClone(
            this.filters.users.values
          );

          // Getting the range date
          let date_filters = this.selected_filters.date.selected_values;

          // Filtering the user base on the range date for doe
          this.selected_filters.users.values =
            this.selected_filters.users.values.filter((item) => {
              return (
                item.doe == null ||
                (item.doe != null &&
                  moment(item.doe).isBetween(
                    date_filters[0],
                    date_filters[1],
                    null,
                    []
                  ))
              );
            });

          if (this.selected_filters.users.selected_values.length != 0) {
            this.selected_filters.users.selected_values =
              this.selected_filters.users.selected_values.filter((item) => {
                return (
                  item.doe == null ||
                  (item.doe != null &&
                    moment(item.doe).isBetween(
                      date_filters[0],
                      date_filters[1],
                      null,
                      []
                    ))
                );
              });
          }

          this.current_user_index =
            this.selected_filters.users.values.findIndex(
              (item) => item.id == this.currentUser.id
            );

          this.$forceUpdate();
        }
      }
    },

    // Below function is for the dropdown from which all filters are selected
    columnFilterHandler(event) {
      const filtername = event.target.value;

      if (!Object.keys(this.selected_filters).includes(filtername)) {
        // To add disabled option in the filters to disable the selected option dropdown
        this.filters[filtername].disabled = true;

        // To make a duplicate copy of the filters,this list is used to populate filters feild
        this.selected_filters[filtername] = Object.assign(
          {},
          JSON.parse(JSON.stringify(this.filters[filtername])),
          {
            checked: true,
            selected_values: null,
            selected_options: this.filters[filtername]["options"][0],
            options_error_msg: "",
            values_error_msg: "",
          }
        );

        // This is to clear the dropdown
        this.filter_dropdown = "";
        this.selected_filters[filtername].disabled = true;
        let filter_type = this.selected_filters[filtername].type;
        if (filter_type == "date") {
          this.$nextTick(() => {
            this.optionEventHandler(
              filter_type,
              this.filters[filtername]["options"][0],
              this.selected_filters[filtername]
            );
          });
        }
      }

      if (Object.keys(this.selected_filters).includes(filtername)) {
        this.filters[filtername].disabled = true;
        this.selected_filters[filtername].disabled = true;
      }

      let filter_type = this.selected_filters[filtername].type;
      this.filter_dropdown = "";

      // if (filter_type === "month") {
      //   this.month_config = {
      //     plugins: [
      //       new monthSelectPlugin({
      //         shorthand: true, //defaults to false
      //         dateFormat: "m.y", //defaults to "F Y"
      //         altFormat: "F Y", //defaults to "F Y"
      //         theme: "dark" // defaults to "light"
      //       })
      //     ]
      //   };
      // }

      if (filter_type === "time") {
        this.time_config = {
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          time_24hr: true,
          defaultDate: "00:00:00",
        };
        this.selected_filters[filtername].selected_values = [
          this.time_config.defaultDate,
        ];
      } else if (filter_type === "date") {
        this.date_config = {
          disableMobile: true,
          dateFormat: "Y-m-d",
          mode: "single",
          enable: [
            {
              from: "2022-09-01",
              to: moment().format("YYYY-MM-DD"),
            },
          ],
        };
      }
      // this.$forceUpdate();
    },

    // To set value of time from flatpicker in filter object
    getPickerTime(time, item) {
      item.values_error_msg ? (item.values_error_msg = "") : "";
      item.selected_values = [];
      item.selected_values.push(moment(new Date(time)).format("HH:mm:ss"));
      this.$forceUpdate();
    },
    getPickedMonth(date, item) {
      let start_date = moment(date).startOf("month").format("YYYY-MM-DD");
      let end_date = moment(date).endOf("month").format("YYYY-MM-DD");
      item.selected_values = [start_date, end_date];
    },
    // To set value of date from flatpicker in filter object
    getPickerDate(selected_dates, item) {
      item.values_error_msg ? (item.values_error_msg = "") : "";
      item.selected_values = [];
      item.selected_values = selected_dates.map((item) =>
        moment(item).format("YYYY-MM-DD")
      );
      this.$forceUpdate();
    },

    // Below function is to control all options Ex is,is not,is between etc... for datepicker and timepicker
    optionEventHandler(filter_type, filter_options, item) {
      // To clear the value error for all kind of inputs on selection change
      // item.values_error_msg="";
      item.values_error_msg ? (item.values_error_msg = "") : "";
      item.options_error_msg ? (item.options_error_msg = "") : "";

      if (filter_type === "date") {
        // Clearing out the selected list
        item.selected_values = null;
        // console.log("--------- ",item);

        // Resting date filter
        this.$refs.date_flatpickr[0].fp.clear();

        // To reset datepicker for single selection
        this.date_config.mode = "single";

        // Modifying datepicker config and values
        if (filter_options === "is between") {
          this.date_config.mode = "range";
          this.show_datepicker = true;
        } else if (filter_options === "this week") {
          let date_range = [
            moment().startOf("week").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ];
          this.date_config.mode = "range";
          this.$refs.date_flatpickr[0].fp.setDate(date_range);
          item.selected_values = date_range;
          this.show_datepicker = false;
        } else if (filter_options === "last week") {
          let date_range = [
            moment().startOf("week").subtract(7, "days").format("YYYY-MM-DD"),
            moment().endOf("week").subtract(7, "days").format("YYYY-MM-DD"),
          ];
          this.date_config.mode = "range";
          // this.date_config.enable.length=0;
          this.$refs.date_flatpickr[0].fp.setDate(date_range);
          item.selected_values = date_range;
          this.show_datepicker = false;
        } else if (filter_options === "this month") {
          let date_range = [
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ];
          this.date_config.mode = "range";
          item.selected_values = date_range;
          this.$refs.date_flatpickr[0].fp.setDate(date_range);
          this.show_datepicker = false;
        } else if (filter_options === "last month") {
          let date_range = [
            moment().subtract("month", 1).startOf("month").format("YYYY-MM-DD"),
            moment().subtract("month", 1).endOf("month").format("YYYY-MM-DD"),
          ];
          this.date_config.mode = "range";
          item.selected_values = date_range;
          this.$refs.date_flatpickr[0].fp.setDate(date_range);
          this.show_datepicker = false;
        } else if (filter_options === "this year") {
          let date_range = [
            moment().startOf("year").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ];
          this.date_config.mode = "range";
          item.selected_values = date_range;
          this.$refs.date_flatpickr[0].fp.setDate(date_range);
          this.show_datepicker = false;
        } else if (filter_options === "today") {
          let date_range = [moment().format("YYYY-MM-DD")];
          this.date_config.mode = "single";
          item.selected_values = date_range;
          this.$refs.date_flatpickr[0].fp.setDate(date_range);
          this.show_datepicker = false;
        } else if (filter_options === "yesterday") {
          let date_range = [moment().subtract("day", 1).format("YYYY-MM-DD")];
          this.date_config.mode = "single";
          item.selected_values = date_range;
          this.$refs.date_flatpickr[0].fp.setDate(date_range);
          this.show_datepicker = false;
        } else if (filter_options === "is") {
          this.date_config.mode = "single";
          this.show_datepicker = true;
          if (this.set_filters) {
            item.selected_values = [
              moment(this.set_filters.date).format("YYYY-MM-DD"),
            ];
            this.$refs.date_flatpickr[0].fp.setDate(item.selected_values[0]);
          }
        } else if (filter_options === "is not") {
          this.date_config.mode = "single";
          this.show_datepicker = true;
          if (this.set_filters) {
            item.selected_values = [
              moment(this.set_filters.date).format("YYYY-MM-DD"),
            ];
            this.$refs.date_flatpickr[0].fp.setDate(item.selected_values[0]);
          }
        } else if (filter_options === "in") {
          this.date_config.mode = "range";
          this.show_datepicker = true;
        } else if (filter_options === "<=") {
          this.date_config.mode = "single";
          this.show_datepicker = true;
        } else if (filter_options === ">=") {
          this.date_config.mode = "single";
          this.show_datepicker = true;
        } else if (filter_options === "less than") {
          this.date_config.mode = "single";
          this.show_datepicker = true;
        } else if (filter_options === "greater than") {
          this.date_config.mode = "single";
          this.show_datepicker = true;
        } else {
        }
        if (this.page == "tm_report") {
          // console.log("Im called");
          this.filterUserByDoe();
        }
      }
      this.$forceUpdate();
    },
    // To validate all filter options for empty values
    validator() {
      let check = true;
      // console.log("selected values ",this.selected_filters);
      if (Object.keys(this.selected_filters).length > 0) {
        // console.log(this.selected_filters[i]);
        for (let i in this.selected_filters) {
          let obj = this.selected_filters[i];

          obj["selected_values"] == ""
            ? (obj["selected_values"] = null)
            : obj["selected_values"] == []
            ? (obj["selected_values"] = null)
            : "";

          // For options dropdown------------
          if (
            obj["selected_options"] == null &&
            obj["disabled"] &&
            obj["checked"]
          ) {
            obj["options_error_msg"] = "** Options field cannot be empty **";
            check = false;
            // break;
          } else {
            obj["options_error_msg"] = "";
            check == false ? "" : (check = true);
          }

          // For values dropdown
          if (
            obj["selected_values"] == null &&
            obj["disabled"] &&
            obj["checked"] &&
            obj["type"] != "hidden"
          ) {
            obj["values_error_msg"] = "** Filter values cannot be empty **";
            check = false;
            // break;
          } else {
            // check=true;
            obj["values_error_msg"] = "";
            check == false ? "" : (check = true);
          }
        }
      }
      this.$forceUpdate();
      return check;
    },

    // To call api according to filter
    applyFilter() {
      // console.log("REDMINE ----- ", this.serverParams, this.page);
      // Below is the object which is going to be sent to api
      // console.log("validation",this.validator());
      if (this.validator()) {
        this.disabled = true;
        let temp = JSON.parse(JSON.stringify(this.selected_filters));
        for (let i in temp) {
          // console.log(temp[i]);
          if (temp[i]["checked"] && temp[i]["disabled"]) {
            if (
              temp[i]["selected_values"] instanceof Object &&
              !(temp[i]["selected_values"] instanceof Array)
            ) {
              if (this.page == "tm_report" && i == "status") {
                temp[i]["selected_values"] = [
                  temp[i]["selected_values"]["name"],
                ];
              } else {
                temp[i]["selected_values"] = [temp[i]["selected_values"]["id"]];
              }
            }
            if (
              temp[i]["selected_values"] instanceof Object &&
              temp[i]["selected_values"] instanceof Array
            ) {
              const exclude_pages = [
                "tm_report",
                "y_report",
                "users",
                "snapshot_report",
                "assets_history",
                "new_assets",
              ];
              const exclude_fields = [
                "projects",
                "teams",
                "users",
                "sub_client",
                "clients",
                "user role",
                "user type",
                "report_to",
                "approved_by",
                "organizations",
                "assets",
                "category",
              ];
              if (
                exclude_pages.includes(this.page) &&
                exclude_fields.includes(i)
              ) {
                let tempObj = temp[i]["selected_values"];
                temp[i]["selected_values"] = tempObj.map((item) => item.id);
                // temp[i]['selected_values']=[temp[i]['selected_values']['name']]
              }
            }
            // For merging the selected_options,selected_values array in the original values and options array
            temp[i]["options"] = temp[i]["selected_options"];
            temp[i]["values"] = temp[i]["selected_values"];
            // For deleting the temporary selected_values and selected_options array
            delete temp[i]["selected_options"];
            delete temp[i]["selected_values"];
            delete temp[i]["disabled"];
            delete temp[i]["checked"];
            delete temp[i]["options_error_msg"];
            delete temp[i]["values_error_msg"];
            // console.log(temp,temp[i]);
          } else {
            delete temp[i];
          }
        }

        this.serverParams.filters = temp;
        // this.serverParams.page = 1;
        // this.serverParams.perpage = 100;
        if (this.page == "users") {
          user.search_user(this.serverParams).then((result) => {
            this.$emit(
              "redmine-filter-data",
              result.data.data.data,
              result.data.data.total
            );
          });
        }
        if (this.page == "y_report") {
          // console.log("=== YOUR REPORT")
          user.search_your_report(this.serverParams).then((result) => {
            this.$emit(
              "redmine-filter-data",
              result.data.data.data,
              result.data.data.total
            );
          });
          // this.$root.$emit("callFunction");
        }
        if (this.page == "snapshot_report") {
          user.getSnapshot(this.serverParams).then((result) => {
            this.$emit("redmine-filter-data", result.data.data);
          });
        }
        if (this.page == "assets_history") {
          user.getAssetsHistory(this.serverParams).then((result) => {
            this.$emit(
              "redmine-filter-data",
              result.data.data.data,
              result.data.data.total
            );
          });
        }
        if (this.page == "new_assets") {
          user.getAssets(this.serverParams).then((result) => {
            this.$emit(
              "redmine-filter-data",
              result.data.data.data,
              result.data.data.total,
              result.data.data.total_price
            );
          });
        }
        if (this.page == "tm_report") {
          // console.log("team report called")
          let params =
            Object.keys(this.selected_filters).length > 0
              ? this.selected_filters
              : {};
          EventBus.$emit("reset-page-perpage", {
            filters: {},
            sort: [
              {
                field: "",
                type: "asc",
              },
            ],
            page: 1,
            perpage: 100,
          });
          this.$emit("call-teammates-report", params);
          // this.$root.$emit("callTeammatesReport",this.selected_filters.status.selected_values);
        }
        // To change the perpage and page tab selection to default values in CustomPaginationComponent
        // EventBus.$emit("reset-page-perpage", { page: 1, perpage: 100 });
      }
      this.disabled = false;
    },

    // To reset the filters
    resetFilter() {
      if (this.page == "tm_report") {
        // For status
        this.selected_filters.status.selected_values =
          this.selected_filters.status.values[1];
        this.selected_filters.status.selected_options =
          this.selected_filters.status.options[0];

        if (
          this.currentUser.user_role == 1 ||
          this.currentUser.user_role == 3
        ) {
          !this.selected_filters.users.checked
            ? (this.selected_filters.users.checked = true)
            : null;
          if (!this.selected_filters.users.disabled) {
            this.selected_filters.users.disabled = true;
            this.filters["users"].disabled = true;
          }
          // For date
          this.selected_filters.date.selected_options =
            this.selected_filters.date.options[10];
          this.optionEventHandler(
            "date",
            "this month",
            this.selected_filters.date
          );
          // For user
          this.selected_filters.users.selected_options =
            this.selected_filters.users.options[1];
          this.selected_filters.users.selected_values = [
            this.selected_filters.users.values[this.current_user_index],
          ];
        } else {
          // For date
          this.selected_filters.date.selected_options =
            this.selected_filters.date.options[0];
          this.optionEventHandler(
            "date",
            "last month",
            this.selected_filters.date
          );
        }

        for (let i in this.selected_filters) {
          if (
            this.currentUser.user_role == 1 ||
            this.currentUser.user_role == 3
          ) {
            if (i != "date" && i != "status" && i != "users") {
              delete this.selected_filters[i];
              this.$forceUpdate();
            }
          } else {
            if (i != "date" && i != "status") {
              delete this.selected_filters[i];
              this.$forceUpdate();
            }
          }
        }
      } else if (this.page == "snapshot_report") {
        this.$refs.month_picker[0].setValue(new Date());
        this.getPickedMonth(new Date(), this.selected_filters.month);
        for (let i in this.selected_filters) {
          if (i !== "month") {
            delete this.selected_filters[i];
          }
        }
      } else if (this.page == "assets_history") {
        // this.columnFilterHandler({ target: { value: "status" } });

        this.set_filters = null;
        this.columnFilterHandler({ target: { value: "date" } });

        // For date
        this.selected_filters.date.selected_options =
          this.selected_filters.date.options[11];

        this.selected_filters.date.checked = true;

        this.selected_filters.date.selected_values = [
          moment().startOf("year").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];

        for (let i in this.selected_filters) {
          if (i !== "date" && i !== "status") {
            delete this.selected_filters[i];
          }
          // else if (i !== "status") {
          // delete this.selected_filters[i];
          // }
        }

        // For status
        this.selected_filters.status.selected_options =
          this.selected_filters.status.options[0];
        this.selected_filters.status.selected_values =
          this.selected_filters.status.values[0];

        this.selected_filters.status.checked = true;

        this.$nextTick(() => {
          this.optionEventHandler(
            "date",
            "this year",
            this.selected_filters.date
          );
        });

        // if (!this.set_filters) {
        //   // For date
        //   this.selected_filters.date.selected_options =
        //     this.selected_filters.date.options[11];

        //   this.selected_filters.date.checked = true;

        //   this.selected_filters.date.selected_values = [
        //     moment().startOf("year").format("YYYY-MM-DD"),
        //     moment().format("YYYY-MM-DD"),
        //   ];

        //   this.$nextTick(() => {
        //     this.optionEventHandler(
        //       "date",
        //       this.set_filters ? "is" : "this year",
        //       this.selected_filters.date
        //     );
        //   });
        // }
      } else if (this.page == "new_assets") {
        // this.columnFilterHandler({ target: { value: "status" } });
        for (let i in this.selected_filters) {
          if (i !== "status") {
            delete this.selected_filters[i];
          }
        }
        this.selected_filters.status.selected_options =
          this.selected_filters.status.options[0];
        this.selected_filters.status.selected_values =
          this.selected_filters.status.values[0];

        this.selected_filters.status.checked = true;
      } else {
        this.selected_filters = {};
      }
      for (let i in this.filters) {
        if (this.page == "tm_report") {
          if (i !== "date" && i !== "status" && i !== "users") {
            this.filters[i].disabled = false;
            this.filters[i].selected_values = null;
            this.filters[i].selected_options = null;
          }
        } else {
          this.filters[i].disabled = false;
          this.filters[i].selected_values = null;
          this.filters[i].selected_options = null;
        }
      }
      this.filter_dropdown = "";
      if (this.page == "tm_report") {
        this.applyFilter();
      } else {
        this.serverParams.page = 1;
        this.serverParams.perpage = 100;
        this.$emit("reset-server-params", this.serverParams);
      }
      this.$forceUpdate();
    },

    // To delete the filters
    deleteFilter(key) {
      // if (key == "date") {
      //   delete this.selected_filters[key];
      //   this.filters[key].disabled = false;
      //   this.$forceUpdate();
      //   return;
      // }
      this.filter_dropdown = "";
      this.filters[key].disabled = false;
      this.selected_filters[key].disabled = false;
      this.selected_filters[key].checked = true;
      this.selected_filters[key].selected_values = null;
      this.selected_filters[key].selected_options = "is";
      this.selected_filters[key].options_error_msg = "";
      this.selected_filters[key].values_error_msg = "";
      if (
        Object.values(this.selected_filters).every(
          (item) => item.disabled === false
        )
      ) {
        this.selected_filters = {};
        this.applyFilter();
      }
      this.$forceUpdate();
    },
  },
  updated() {
    // console.log("UPDATED ---------- ");
    // console.log(this.serverParams);
  },
  mounted() {
    // console.log("remdine component mounted--");
    // console.log("MOUNTED ---------- ");
    // console.log(this.serverParams);
    this.getUserPermissions();
    EventBus.$on("parentEvent", (params) => {
      this.page = params;
      // console.log("REDMINE FILTER CALLED.....!");
      this.applyFilter();
      // console.log("from parent event",this.serverParams);
    });
    user.getUserFilters(this.page).then((result) => {
      this.filters = result.data.data;

      if (this.page == "tm_report") {
        this.columnFilterHandler({ target: { value: "date" } });
        this.columnFilterHandler({ target: { value: "status" } });

        // For status
        this.selected_filters.status.selected_values = this.set_filters
          ? this.selected_filters.status.values[2]
          : this.selected_filters.status.values[1];

        if (
          this.currentUser.user_role == 1 ||
          this.currentUser.user_role == 3
        ) {
          // For Users
          this.columnFilterHandler({ target: { value: "users" } });
          this.current_user_index =
            this.selected_filters.users.values.findIndex(
              (item) =>
                item.id ==
                (this.set_filters
                  ? this.set_filters.user_id
                  : this.currentUser.id)
            );
          this.selected_filters.users.selected_options = this.set_filters
            ? this.selected_filters.users.options[0]
            : this.selected_filters.users.options[1];
          this.selected_filters.users.selected_values = [
            this.selected_filters.users.values[this.current_user_index],
          ];

          // For date
          this.selected_filters.date.selected_options = this.set_filters
            ? this.selected_filters.date.options[1]
            : this.selected_filters.date.options[10];

          this.selected_filters.date.selected_values = this.set_filters
            ? [moment(this.set_filters.date).format("YYYY-MM-DD")]
            : [
                moment().startOf("month").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
              ];

          this.$nextTick(() => {
            this.optionEventHandler(
              "date",
              this.set_filters ? "is" : "this month",
              this.selected_filters.date
            );
          });
        } else {
          // For date
          this.selected_filters.date.selected_options =
            this.selected_filters.date.options[0];

          this.selected_filters.date.selected_values = [
            moment()
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD"),
            moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
          ];

          this.$nextTick(() => {
            this.optionEventHandler(
              "date",
              "last month",
              this.selected_filters.date
            );
          });
        }
        this.applyFilter();
      } else if (this.page == "users") {
        this.applyFilter();
        this.columnFilterHandler({ target: { value: "username" } });
        this.selected_filters.username.checked = false;
        // console.log("hello users");
      } else if (this.page == "y_report") {
        this.columnFilterHandler({ target: { value: "date" } });
        this.selected_filters.date.selected_options =
          this.selected_filters.date.options[6];
        this.selected_filters.date.selected_values = [
          moment().format("YYYY-MM-DD"),
        ];
        this.$nextTick(() => {
          this.optionEventHandler("date", "today", this.selected_filters.date);
        });
        this.applyFilter();
      } else if (this.page == "snapshot_report") {
        this.columnFilterHandler({ target: { value: "month" } });
        this.$nextTick(() => {
          this.$refs.month_picker[0].setValue(new Date());
          this.getPickedMonth(new Date(), this.selected_filters.month);
          this.applyFilter();
        });
      } else if (this.page == "assets_history") {
        if (this.set_filters) {
          this.columnFilterHandler({ target: { value: "assets" } });
          this.selected_filters.assets.selected_values =
            this.selected_filters.assets.values.filter(
              (i) => i.id == this.set_filters.asset_id
            );
          this.$forceUpdate();
        } else {
          this.columnFilterHandler({ target: { value: "date" } });
          // For date
          this.selected_filters.date.selected_options =
            this.selected_filters.date.options[11];

          this.selected_filters.date.selected_values = [
            moment().startOf("year").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ];

          this.$nextTick(() => {
            this.optionEventHandler(
              "date",
              "this year",
              this.selected_filters.date
            );
          });
        }

        this.columnFilterHandler({ target: { value: "status" } });

        // For status
        this.selected_filters.status.selected_options =
          this.selected_filters.status.options[0];
        this.selected_filters.status.selected_values =
          this.selected_filters.status.values[0];

        this.applyFilter();
      } else if (this.page == "new_assets") {
        this.columnFilterHandler({ target: { value: "status" } });
        if (this.set_filters) {
          this.columnFilterHandler({ target: { value: "assets" } });
          this.selected_filters.assets.selected_values =
            this.selected_filters.assets.values.filter(
              (i) => i.id == this.set_filters.asset_id
            );
          this.$forceUpdate();
        }

        // For status
        this.selected_filters.status.selected_options =
          this.selected_filters.status.options[0];
        this.selected_filters.status.selected_values =
          this.selected_filters.status.values[0];

        // For date
        // this.selected_filters.date.selected_options =
        //   this.selected_filters.date.options[11];

        // this.selected_filters.date.selected_values = [
        //   moment().startOf("year").format("YYYY-MM-DD"),
        //   moment().format("YYYY-MM-DD"),
        // ];

        // this.$nextTick(() => {
        //   this.optionEventHandler(
        //     "date",
        //     "this year",
        //     this.selected_filters.date
        //   );
        // });

        this.applyFilter();
      } else {
        this.applyFilter();
      }
    });
  },
};
</script>

<style scoped>
.filter_field {
  font-size: 16px;
  height: 32px;
  padding: 3px 10px;
  /* padding:0px; */
  /* border: 1px solid #e8e8e8; */
  border-radius: 5px;
  width: 100%;
  margin-bottom: 0px;
}

.validation-true-dp::placeholder {
  color: crimson;
}

.validation-true-dp {
  border: 1px solid crimson;
}

:deep .validation-true .multiselect__tags {
  border: 1px solid crimson;
  color: crimson;
}

:deep .validation-true .multiselect__placeholder {
  color: crimson;
}

:deep .validation-true .multiselect__select:before {
  border-color: crimson transparent transparent;
}

.custom_multiselect {
  min-height: 32px !important;
  font-size: 16px;
}

:deep .custom_multiselect .multiselect__tags {
  height: max-content !important;
  min-height: 32px !important;
  padding-top: 0px !important;
  /* background:gold; */
}

:deep .custom_multiselect .multiselect__input {
  margin-bottom: 0px !important;
  margin-top: 5px !important;
  padding: 0px !important;
  font-size: 14px;
}

:deep .custom_multiselect .multiselect__content-wrapper {
  font-size: 14px;
}

:deep .custom_multiselect .multiselect__tags .multiselect__tag {
  margin-bottom: 0px !important;
  font-size: 12px;
}

:deep .custom_multiselect .multiselect__single {
  font-size: 15px;
  padding: 0px 4px !important;
  padding-top: 5px !important;
  margin: 0px !important;
  min-height: 0px !important;
  /* height:32px !important; */
}

:deep .custom_multiselect .multiselect__select {
  height: 32px !important;
  min-height: 32px !important;
  margin: 0px !important;
  padding: 0px !important;
  min-height: auto !important;
  /* background-color:gold !important; */
}

:deep .custom_multiselect .multiselect__placeholder {
  padding: 4px 0px !important;
  margin: 0px;
}

option:disabled {
  background: lightgray;
}

#filter_selection {
  font-size: 14px;
  height: 34px;
  padding: 2px 10px;
  border: 1px solid #e8e8e8;
  width: 14rem;
}

#crossBtn {
  font-size: 25px;
  font-weight: bold;
  color: crimson;
  cursor: pointer;
}

#crossBtn:hover {
  color: darkred;
}

input[type="checkbox"] {
  width: 1em;
  height: 1em;
  background: #4e73df;
}

.error-msg {
  color: red;
  display: block;
  position: absolute;
  /* left:-10px; */
  top: -1.2rem;
}
</style>
